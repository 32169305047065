import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { finalize, takeUntil } from 'rxjs/operators';
import { DbaasApiService } from 'src/app/dbaas/dbaas-shared/dbaas-services/dbaas-api.service';
import { OktaService } from 'src/app/factory/okta.service';
import { MenuService } from 'src/app/services/menu.service';
import { LoggerService } from 'src/app/shared/services/logger.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  userDashboardFavorites: string[];
  userInfo: any;
  constructor(
    private oktaService: OktaService,
    public router: Router,
    private menuService: MenuService,
    private loggerService: LoggerService,
    private dbaasApiService: DbaasApiService,) {
    this.oktaService.userInfo$.subscribe((userInfo) => {
      this.userInfo = userInfo;
    });
  }

  ngOnInit() {
    this.dbaasApiService.getAllUserDashboardFavorites().subscribe({
      next: (response) => {
        const favs = response?.data ?? [];
        favs.forEach(fav => {
          const uiReportId = fav.uiReportId;
          for (const property in this.menuService.allMenus) {
            this.menuService.allMenus[property].forEach(parentMenuItem => {
              const matchingChild = this.findMatchingChild(parentMenuItem.children, uiReportId);
              if (matchingChild && typeof fav.caption === 'undefined') {
                if (!parentMenuItem.routerLink && !parentMenuItem.url) {
                  fav.caption = parentMenuItem.name
                } else {
                  fav.caption = matchingChild.name;
                }
                fav.routerLink = matchingChild.routerLink;
                return;
              }
          });
            }
        });
        this.userDashboardFavorites = favs
      },
      error: (error) => {
        this.loggerService.logger({
          type: "error",
          class: "HomeComponent",
          method: "ngOnInit",
          message: "couldn't get the user's dashboard favorites",
          error,
        });
      }
    })
  }

  navigateToUserDashboardFavorite(routerLink: string) {
    this.router.navigateByUrl(routerLink);
  }

  findMatchingChild = (children, uiReportId) => {
    for (const child of children) {
      if (child.routerLink) {
        const matches = /dbaas\/report\/(\d+)/.exec(child.routerLink)
        if (matches?.length == 2 && uiReportId == Number(matches[1])) {
          return child;
        }
      }
      if (child.children?.length) {
        const result = this.findMatchingChild(child.children, uiReportId);
        if (result) {
          return result;
        }
      }
    }
    return null;
  }
}
