import { CCGFilterFields, CCGFiltersBinding } from 'src/app/rli/ccg/ccg-shared/ccg-constants/ccg-filter-bindings';
import { OIFilterFields, OIFiltersBinding } from '../../rli/opportunity-identified/oi-shared/oi-constants/oi-filter-bindings';
import { HomeConstants, TabsConstants } from '../../rli/shared/constants/home-constants';
import { QPIFilterFields, QPIFiltersBinding } from 'src/app/qpi/shared/constants/qpi-filter-bindings';
import { MIFilterFields, MIFiltersBinding } from 'src/app/mi/shared/constants/mi-filter-bindings';
import { FiaFilterFields, FiaFiltersBinding } from 'src/app/fia/shared/constants/filters-bindings';

export class LookerConstants {
    filterBindings: any = OIFiltersBinding.bindingInfo;
    static readonly AGING_VENDOR_ID: string = "vendor"
    static readonly INDICES_MRR_SITE_PURSUITS: string = 'indicesmrr-sitepursuits';
    static readonly INDICES_MRR_SITE_CHARTS: string = 'indicesmrr-sitecharts';

    static readonly INDICES_MRR_EXTRACT: string = 'indicesmrr-extract-download';
    static readonly INDICES_MRR_EXTRACT_ACTIVITY_DRILL_DOWN: string = 'indicesmrr-activitydrilldown-download';

    static readonly INDICES_MRR_SITE_MRR_STATUS_PURSUITS: string = 'indicesmrr-pursuitstatuses';
    static readonly INDICES_MRR_SITE_MRR_STATUS_CHARTS: string = 'indicesmrr-chartstatuses';
    static readonly INDICES_MRR_AF_PURSUITS: string = 'indicesmrr-actualvsforecast-pursuitsgrid';
    static readonly INDICES_MRR_AF_CHARTS: string = 'indicesmrr-actualvsforecast-chartsgrid';

    static readonly INDICES_MRR_TREND_WEEKLY_ABS_CHARTS: string = 'indicesmrr-trend-pursuitgroupcountscharts';
    static readonly INDICES_MRR_TREND_WEEKLY_CHNG_CHARTS: string = 'indicesmrr-trend-pursuitgroupchangechartcounts';
    static readonly INDICES_MRR_TREND_MONTHLY_ABS_CHARTS: string = 'indicesmrr-trend-pursuitgroupcountsmonthlycharts';
    static readonly INDICES_MRR_TREND_MONTHLY_CHNG_CHARTS: string = 'indicesmrr-trend-pursuitgroupchangechartcountsmonthly';

    static readonly INDICES_MRR_TREND_WEEKLY_ABS_PURSUITS: string = 'indicesmrr-trend-pursuitgroupcounts';
    static readonly INDICES_MRR_TREND_WEEKLY_CHNG_PURSUITS: string = 'indicesmrr-trend-pursuitgroupchangecounts';
    static readonly INDICES_MRR_TREND_MONTHLY_ABS_PURSUITS: string = 'indicesmrr-trend-pursuitgroupcountsmonthly';
    static readonly INDICES_MRR_TREND_MONTHLY_CHNG_PURSUITS: string = 'indicesmrr-trend-pursuitgroupchangecountsmonthly';

    static readonly INDICES_MRR_AGING_PURSUITS: string = 'indicesmrr-agingreport-agingofpursuits';
    static readonly INDICES_MRR_AGING_CHARTS: string = 'indicesmrr-agingreport-agingofcharts';

    static readonly INDICES_MRR_ACTIVITY_LOG_CHARTS: string = 'indicesmrr-activitylog-sitedetails';
    static readonly INDICES_MRR_ACTIVITY_LOG_PURSUITS: string = 'indicesmrr-activitylog-sitedetails';

    static readonly SERVICE_YEAR_LABEL: string = 'Service Year';

    static readonly INDICES_DOWNLOAD_OVERVIEW_TYPE: string = 'overview';
    static readonly INDICES_DOWNLOAD_ACTIVITY_DRILL_DOWN_TYPE: string = 'activity';
    static readonly INDICES_DOWNLOAD_AF_TYPE: string = 'actualvsforecast';
    static readonly INDICES_DOWNLOAD_AGING_TYPE: string = 'agingreport';
    static readonly INDICES_DOWNLOAD_TREND_TYPE: string = 'trend';
    static readonly INDICES_DOWNLOAD_ACTIVITY_TYPE: string = 'activitylog';
    static readonly INDICES_DOWNLOAD_OVERVIEW_SUBTYPE: string = 'medical_record';

    static readonly OVERVIEW_SITE_DETAILS_DOWNLOAD: string = 'Site Details Download';
    static readonly OVERVIEW_SITE_DETAILS_CHARTS_FILENAME: string = '' + HomeConstants.MRR + ' - ' + TabsConstants.OVERVIEW.label + ' - Site Details - ' + HomeConstants.CHARTS_TEXT + '';
    static readonly OVERVIEW_SITE_DETAILS_PURSUITS_FILENAME: string = '' + HomeConstants.MRR + ' - ' + TabsConstants.OVERVIEW.label + ' - Site Details - ' + HomeConstants.PURSUITS_TEXT + '';
    static readonly OVERVIEW_MRR_STATUS_DOWNLOAD: string = '' + HomeConstants.MRR + ' Status Breakdown Download';
    static readonly OVERVIEW_MRR_STATUS_CHARTS_FILENAME: string = '' + HomeConstants.MRR + ' - Status Breakdown - ' + HomeConstants.CHARTS_TEXT + '';
    static readonly OVERVIEW_MRR_STATUS_PURSUITS_FILENAME: string = '' + HomeConstants.MRR + ' - Status Breakdown- ' + HomeConstants.PURSUITS_TEXT + '';
    static readonly AF_DOWNLOAD: string = 'Actual vs Forecasted Completion Download';
    static readonly AF_FILENAME: string = '' + HomeConstants.MRR + ' - ' + TabsConstants.ACTUALS_VS_FORECASTS.label + '';
    static readonly TREND_DOWNLOAD: string = 'Trend Download';
    static readonly TREND_FILENAME: string = '' + HomeConstants.MRR + ' - ' + TabsConstants.TREND.label + '';
    static readonly AGING_DOWNLOAD: string = 'Aging Report Download';
    static readonly AGING_CHARTS_FILENAME: string = '' + HomeConstants.MRR + ' - ' + TabsConstants.AGING_REPORT.label + ' - Aging of ' + HomeConstants.CHARTS_TEXT + ' by Vendor Type and Status';
    static readonly AGING_PURSUITS_FILENAME: string = '' + HomeConstants.MRR + ' - ' + TabsConstants.AGING_REPORT.label + ' - Aging of ' + HomeConstants.PURSUITS_TEXT + ' by Vendor Type and Status';
    static readonly ACTIVITY_LOG_SITE_DETAILS_DOWNLOAD: string = 'Site Activity Details Download';
    static readonly ACTIVITY_LOG_SITE_DETAILS_FILENAME: string = '' + HomeConstants.MRR + ' - ' + TabsConstants.ACTIVITY_LOG.label + ' - Site Activity Details';
    static readonly EXTRACT_OVERVIEW_DOWNLOAD: string = 'Extract Download';
    static readonly EXTRACT_OVERVIEW_FILENAME: string = '' + HomeConstants.MRR + ' - ' + TabsConstants.EXTRACT.label + '';
    static readonly EXTRACT_ACTIVITY_DRILL_DOWN_DOWNLOAD: string = 'Extract Activity Drill Down Download';
    static readonly EXTRACT_ACTIVITY_DRILL_DOWN_FILENAME: string = '' + HomeConstants.MRR + ' - ' + TabsConstants.EXTRACT.label + '';

    static readonly INDICES_DOWNLOAD_TREND_WEEKLY: string = 'Weekly';
    static readonly INDICES_DOWNLOAD_TREND_MONTHLY: string = 'Monthly';

    // <--OPPORTUNITY IDENTIFIED
    static readonly OPPORTUNITY_BY_GAP_CONFIDENCE_LEVEL = 'indicesopportunity-opportunitybygapconfidence';
    static readonly OI_OPPORTUNITY_BY_GAP_CONFINDENCE_DOWNLOAD_TYPE: string = 'opportunitybygapconfidence';
    static readonly OI_OPPORTUNITY_BY_GAP_CONFIDENCE_DOWNLOAD: string = 'Opportunity by Gap Confidence Level Download';
    static readonly OI_OPPORTUNITY_BY_GAP_CONFIDENCE_FILENAME: string = 'Opportunity by Gap Confidence Level';
    static readonly OI_OPPORTUNITY_BY_GAP_CONFIDENCE_SHEETNAME: string = 'Opp by Gap Confidence Level';

    static readonly MEMBER_LEVEL_POTENTIAL_FACTOR = 'indicesopportunity-memberlevelpotentialfactor';
    static readonly OI_MEMBER_LEVEL_POTENTIAL_FACTOR_DOWNLOAD_TYPE = 'memberlevelpotentialfactor';
    static readonly OI_MEMBER_LEVEL_POTENTIAL_FACTOR_DOWNLOAD = 'Opportunity by Potential RAF Improvement Group Download';
    static readonly OI_MEMBER_LEVEL_POTENTIAL_FACTOR_FILENAME = 'Opportunity by Potential RAF Improvement Group';
    static readonly OI_MEMBER_LEVEL_POTENTIAL_FACTOR_SHEETNAME = 'OppByPotentialRAFImprovementGrp';

    static readonly OPPORTUNITY_BY_CONDITION_CATEGORY = 'indicesopportunity-opportunitybyconditioncategory';
    static readonly OI_OPPORTUNITY_BY_CONDITION_CATEGORY_TYPE = 'opportunitybyconditioncategory';
    static readonly OI_OPPORTUNITY_BY_CONDITION_CATEGORY_DOWNLOAD = 'Opportunity by Condition Category Download';
    static readonly OI_OPPORTUNITY_BY_CONDITION_CATEGORY_FILENAME = 'Opportunity by Condition Category';
    static readonly OI_OPPORTUNITY_BY_CONDITION_CATEGORY_SHEETNAME = 'Opp by Condition Category';

    static readonly EXTRACT_MEMBER_DETAIL = 'indicesopportunity-extract';
    static readonly OI_EXTRACT_MEMBER_DETAIL_DOWNLOAD_TYPE = 'indicesopportunity-extract';
    static readonly OI_EXTRACT_MEMBER_DETAIL_DOWNLOAD = 'Opportunity Identified Extract Download';
    static readonly OI_EXTRACT_MEMBER_DETAIL_FILENAME = 'Opportunity Identified - Extract';
    static readonly OI_EXTRACT_MEMBER_DETAIL_SHEETNAME = 'Extract';
    // OPPORTUNITY IDENTIFIED-->

    // <--ES Dashboard
    // RequestName in payload
    static readonly ES_EXTRACT_VV_MEMBER_DETAIL = 'indiceses-vvdownload';
    static readonly ES_EXTRACT_TCV_MEMBER_DETAIL = 'indiceses-tcvdownload';
    static readonly ES_EXTRACT_PV_MEMBER_DETAIL = 'indiceses-pvdownload';

    // type in requestbody
    static readonly ES_EXTRACT_VV_MEMBER_DETAIL_DOWNLOAD_TYPE = 'indiceses-vvdownload';
    static readonly ES_EXTRACT_TCV_MEMBER_DETAIL_DOWNLOAD_TYPE = 'indiceses-tcvdownload';
    static readonly ES_EXTRACT_PV_MEMBER_DETAIL_DOWNLOAD_TYPE = 'indiceses-pvdownload';

    //cardtitle(comment popup title)
    static readonly ES_EXTRACT_VV_MEMBER_DETAIL_DOWNLOAD_CARDTITLE = 'Encounter Support Visit View Extract Download';
    static readonly ES_EXTRACT_TCV_MEMBER_DETAIL_DOWNLOAD_CARDTITLE = 'Encounter Support Targeted Condition View Extract Download';
    static readonly ES_EXTRACT_PV_MEMBER_DETAIL_DOWNLOAD_CARDTITLE = 'Encounter Support Provider View Extract Download';


    //uiDisplayName - SheetName
    static readonly ES_EXTRACT_VV_SHEETNAME = 'Visit View';
    static readonly ES_EXTRACT_TCV_SHEETNAME = 'Targeted Condition View';
    static readonly ES_EXTRACT_PV_SHEETNAME = 'Provider View';
    // ES Dashboard-->

    // <--CONVERGED CARE GAPS
    static readonly CCG_EXTRACT_MEMBER_DETAIL = 'indicesca-extract';
    static readonly CCG_EXTRACT_MEMBER_DETAIL_DOWNLOAD_TYPE = 'indicesca-extract';
    static readonly CCG_EXTRACT_MEMBER_DETAIL_DOWNLOAD = 'Converged Care Gaps Extract Download';
    static readonly CCG_EXTRACT_MEMBER_DETAIL_FILENAME = 'Converged Care Gaps - Extract';
    static readonly CCG_EXTRACT_MEMBER_DETAIL_REPORTNAME = 'Members w/Gaps';
    static readonly CCG_EXTRACT_MEMBER_DETAIL_SHEETNAME = 'Member Details';

    static readonly CCG_TOP_GAPS_BY_GAP_DESCRIPTION = 'indicesca-topgapsbygapdescription';
    static readonly CCG_TOP_GAPS_BY_GAP_DESCRIPTION_DOWNLOAD_TYPE = 'indicesca-topgapsbygapdescription';
    static readonly CCG_TOP_GAPS_BY_GAP_DESCRIPTION_DOWNLOAD = 'Top Gaps by Gap Description Download';
    static readonly CCG_TOP_GAPS_BY_GAP_DESCRIPTION_FILENAME = 'Top Gaps by Gap Description';
    // CONVERGED CARE GAPS-->

    // CONVERGED RISK GAPS START
    static readonly CCG_RISK_GAPS: string = 'indicesca-riskgapsbytype';
    static readonly CCG_RISK_GAPS_DOWNLOAD_TYPE: string = 'indicesca-riskgapsbytype';
    static readonly CCG_RISK_GAPS_DOWNLOAD: string = 'Risk Gaps Download';
    static readonly CCG_RISK_GAPS_FILENAME: string = 'Risk Gaps';
    static readonly CCG_RISK_GAPS_SHEETNAME: string = 'Risk Gaps';
    // CONVERGED RISK GAPS END

    // <--CONVERGED CARE GAPS
    static readonly CCG_TOP_MCP_GRID_DOWNLOAD: string = 'Top Medical Groups Clinic Provider By Gap Count Download';
    static readonly TOP_MCP_GRID_PROVIDER = 'indicesca-topprovidercount';
    static readonly TOP_MCP_GRID_CLINIC = 'indicesca-topcliniccount';
    static readonly TOP_MCP_GRID_MEDICAL_GROUP = 'indicesca-topmgcount';

    static readonly TOP_MCP_GRID_PROVIDER_FILENAME: string = 'Top Provider By Gap Count';
    static readonly TOP_MCP_GRID_CLINIC_FILENAME: string = 'Top Clinic By Gap Count';
    static readonly TOP_MCP_GRID_MEDICAL_GROUP_FILENAME: string = 'Top Medical Groups By Gap Count';

    static readonly TOP_MCP_GRID_PROVIDER_REPORT_NAME: string = 'Top Provider By Gap Count';
    static readonly TOP_MCP_GRID_CLINIC_REPORT_NAME: string = 'Top Clinic By Gap Count';
    static readonly TOP_MCP_GRID_MEDICAL_GROUP_REPORT_NAME: string = 'Top Medical Groups By Gap Count';

    static readonly TOP_MCP_GRID_PROVIDER_SHEETNAME: string = 'Top Provider By Gap Count';
    static readonly TOP_MCP_GRID_CLINIC_REPORT_SHEETNAME: string = 'Top Clinic By Gap Count';
    static readonly TOP_MCP_GRID_MEDICAL_GROUP_SHEETNAME: string = 'Top Medical Groups By Gap Count';

    static readonly CCG_QUALITY_GAPS: string = 'indicesca-qualitygapsbytype';
    static readonly CCG_QUALITY_GAPS_DOWNLOAD_TYPE: string = 'indicesca-qualitygapsbytype';
    static readonly CCG_QUALITY_GAPS_DOWNLOAD: string = 'Quality Gaps Download';
    static readonly CCG_QUALITY_GAPS_FILENAME: string = 'Quality Gaps';
    static readonly CCG_QUALITY_GAPS_SHEETNAME: string = 'Quality Gaps';
    // CONVERGED CARE GAPS-->

    //QPI MEASURE PERFORMANCE SUMMARY

    static readonly QPI_MP_CURRENT_MEASURE_PERFORMANCE = 'indicesqpi-currentmeasureperformance';
    static readonly QPI_MP_CURRENT_MEASURE_PERFORMANCE_DOWNLOAD_TYPE = 'indicesqpi-currentmeasureperformance';
    static readonly QPI_MP_CURRENT_MEASURE_PERFORMANCE_DOWNLOAD = 'Current Measure Performance Download';
    static readonly QPI_MP_CURRENT_MEASURE_PERFORMANCE_FILENAME = 'Current Measure Performance';
    static readonly QPI_MP_CURRENT_MEASURE_PERFORMANCE_SHEETNAME = 'Current Measure Performance';

    //QPI COMPARE CURRENT RATE

    static readonly QPI_MP_COMPARE_CURRENT_RATE = 'indicesqpi-compareflowcharts';
    static readonly QPI_MP_COMPARE_CURRENT_RATE_DOWNLOAD_TYPE = 'indicesqpi-compareflowcharts';
    static readonly QPI_MP_COMPARE_CURRENT_RATE_DOWNLOAD = 'Compare Current Rate Download';
    static readonly QPI_MP_COMPARE_CURRENT_RATE_FILENAME = 'Compare Current Rate';
    static readonly QPI_MP_COMPARE_CURRENT_RATE_DOWNLOAD_FILENAME = 'Compare Current Rate To Analytics Runs';
    static readonly QPI_MP_COMPARE_CURRENT_RATE_SHEETNAME = 'Compare Current Rate To';

    //QPI Compare Performance To
    static readonly QPI_MP_COMPARE_PERFORMANCE_RATE = 'indicesqpi-comparebenchmarks';
    static readonly QPI_MP_COMPARE_PERFORMANCE_RATE_DOWNLOAD_TYPE = 'indicesqpi-comparebenchmarks';
    static readonly QPI_MP_COMPARE_PERFORMANCE_RATE_DOWNLOAD = 'Compare Performance To Download';
    static readonly QPI_MP_COMPARE_PERFORMANCE_RATE_FILENAME = 'Compare Performance Benchmark';
    static readonly QPI_MP_COMPARE_CURRENT_RATE_BENCHMARK_DOWNLOAD_FILENAME = 'Compare Performance to Benchmark';
    static readonly QPI_MP_COMPARE_PERFORMANCE_RATE_SHEETNAME = 'Compare Performance';

    //QPI Current Administrative Rate Trend

    static readonly QPI_MP_ADMINISTRATIVE_RATE_TREND = 'indicesqpi-currentratetrend';
    static readonly QPI_MP_ADMINISTRATIVE_RATE_TREND_DOWNLOAD_TYPE = 'indicesqpi-currentratetrend';
    static readonly QPI_MP_ADMINISTRATIVE_RATE_TREND_DOWNLOAD = 'Current Administrative Rate Trend Download';
    static readonly QPI_MP_ADMINISTRATIVE_RATE_TREND_FILENAME = 'Current Administrative Rate Trend';
    static readonly QPI_MP_ADMINISTRATIVE_RATE_TREND_SHEETNAME = 'Current Administrative Rate Trend';

    //QPI Current Administrative Rate Trend

    static readonly QPI_MP_ADMINISTRATIVE_RATE_TREND_CHILD = 'indicesqpi-currentratetrendchild';
    static readonly QPI_MP_ADMINISTRATIVE_RATE_TREND_CHILD_DOWNLOAD_TYPE = 'indicesqpi-currentratetrendchild';
    static readonly QPI_MP_ADMINISTRATIVE_RATE_TREND_CHILD_DOWNLOAD = 'Current Administrative Rate Trend Download';
    static readonly QPI_MP_ADMINISTRATIVE_RATE_TREND_CHILD_FILENAME = 'Current Administrative Rate Trend';
    static readonly QPI_MP_ADMINISTRATIVE_RATE_TREND_CHILD_SHEETNAME = 'Current Administrative Rate Trend';

    //QPI MEASURE PERFORMANCE DEMOGRAPHICS SUMMARY
    static readonly QPI_MD_CURRENT_MEASURE_PERFORMANCE = 'indicesqpi-currentmeasureperformance';
    static readonly QPI_MD_CURRENT_MEASURE_PERFORMANCE_DOWNLOAD_TYPE = 'indicesqpi-currentmeasureperformance';
    static readonly QPI_MD_CURRENT_MEASURE_PERFORMANCE_DOWNLOAD = 'Current Measure Performance Download';
    static readonly QPI_MD_CURRENT_MEASURE_PERFORMANCE_FILENAME = 'Current Measure Performance';
    static readonly QPI_MD_CURRENT_MEASURE_PERFORMANCE_SHEETNAME = 'Current Measure Performance';


    //QPI MEASURE DEMOGRAPHIC GEOGRAPHIC DISTRIBUTION DOWNLOAD
    static readonly QPI_MD_GEOGRAPHIC_DISTRIBUTION = 'indicesqpi-geographicdistribution';
    static readonly QPI_MD_GEOGRAPHIC_DISTRIBUTION_DOWNLOAD_TYPE = 'indicesqpi-geographicdistribution';
    static readonly QPI_MD_GEOGRAPHIC_DISTRIBUTION_DOWNLOAD = 'Geographic Distribution Download';
    static readonly QPI_MD_GEOGRAPHIC_DISTRIBUTION_FILENAME = 'Geographic Distribution';
    static readonly QPI_MD_GEOGRAPHIC_DISTRIBUTION_SHEETNAME = 'Geographic Distribution';

    // QPI MEASURE DEMOGRAPHIC ETHNICITY DISTRIBUTION DOWNLOAD
    static readonly QPI_MD_ETHNICITY_DISTRIBUTION = 'indicesqpi-ethnicitydistribution';
    static readonly QPI_MD_ETHNICITY_DISTRIBUTION_DOWNLOAD_TYPE = 'indicesqpi-ethnicitydistribution';
    static readonly QPI_MD_ETHNICITY_DISTRIBUTION_DOWNLOAD = 'Ethnicity Distribution Download';
    static readonly QPI_MD_ETHNICITY_DISTRIBUTION_FILENAME = 'Ethnicity Distribution';
    static readonly QPI_MD_ETHNICITY_DISTRIBUTION_SHEETNAME = 'Ethnicity Distribution';

    // QPI  MEASURE DEMOGRAPHIC RACE DISTRIBUTION DOWNLOAD
    static readonly QPI_MD_RACE_DISTRIBUTION = 'indicesqpi-racedistribution';
    static readonly QPI_MD_RACE_DISTRIBUTION_DOWNLOAD_TYPE = 'indicesqpi-racedistribution';
    static readonly QPI_MD_RACE_DISTRIBUTION_DOWNLOAD = 'Race Distribution Download';
    static readonly QPI_MDRACE_DISTRIBUTION_FILENAME = 'Race Distribution';
    static readonly QPI_MD_RACE_DISTRIBUTION_SHEETNAME = 'Race Distribution';

    // QPI  MEASURE DEMOGRAPHIC AGE GENDER DISTRIBUTION DOWNLOAD
    static readonly QPI_MD_AGE_GENDER_DISTRIBUTION = 'indicesqpi-agegenderdistribution';
    static readonly QPI_MD_AGE_GENDER_DISTRIBUTION_DOWNLOAD_TYPE = 'indicesqpi-agegenderdistribution';
    static readonly QPI_MD_AGE_GENDER_DISTRIBUTION_DOWNLOAD = 'Age Sex Distribution Download';
    static readonly QPI_MD_AGE_GENDER_DISTRIBUTION_FILENAME = 'Age Sex Distribution';
    static readonly QPI_MD_AGE_GENDER_DISTRIBUTION_SHEETNAME = 'Age Sex Distribution';

    // QPI  MEASURE DEMOGRAPHIC AGE GENDER DISTRIBUTION DOWNLOAD
    static readonly QPI_RM_RATE_OVERVIEW_DOWNLOAD = 'indicesqpi-rateoverview';
    static readonly QPI_RM_RATE_OVERVIEW_DOWNLOAD_TYPE = 'indicesqpi-rateoverview';
    static readonly QPI_RM_RATE_OVERVIEW_TITLE = 'Rate Overview Download';
    static readonly QPI_RM_RATE_OVERVIEW_FILENAME = 'Rate Overview';
    static readonly QPI_RM_RATE_OVERVIEW_SHEETNAME = 'Rate Overview';

    // MSI MEMBER DEMOGRAPHIC AGE GENDER DISTRIBUTION DOWNLOAD
    static readonly MI_MD_AGE_GENDER_DISTRIBUTION = 'indicesmsi-agegenderdistributionbylob';
    static readonly MI_MD_AGE_GENDER_DISTRIBUTION_DOWNLOAD_TYPE = 'indicesmsi-agegenderdistributionbylob';
    static readonly MI_MD_AGE_GENDER_DISTRIBUTION_DOWNLOAD = 'Age Sex Distribution By Line-of-Business Download';
    static readonly MI_MD_AGE_GENDER_DISTRIBUTION_FILENAME = 'Age Sex Distribution By Line-of-Business';
    static readonly MI_MD_AGE_GENDER_DISTRIBUTION_SHEETNAME = 'Age Sex Distribution By Line-of-Business';

    // MSI MEMBER DEMOGRAPHIC ENROLLMENT BY GEOGRAPHY
    static readonly MI_MD_ENROLLMENT_BY_GEOGRAPHY = 'indicesmsi-enrollmentbygeography';
    static readonly MI_MD_ENROLLMENT_BY_GEOGRAPHY_DOWNLOAD_TYPE = 'indicesmsi-enrollmentbygeography';
    static readonly MI_MD_ENROLLMENT_BY_GEOGRAPHY_DOWNLOAD = 'Enrollment By Geography Download';
    static readonly MI_MD_ENROLLMENT_BY_GEOGRAPHY_FILENAME = 'Enrollment By Geography';
    static readonly MI_MD_ENROLLMENT_BY_GEOGRAPHY_SHEETNAME = 'Enrollment By Geography';

    // MSI PROVIDER DEMOGRAPHIC NUMBERS OF MEMBER BY MEDICAL GROUP DOWNLOAD
    static readonly MI_PD_NUMBER_OF_MEMBERS_BY_MEDICAL_GROUP = 'indicesmsi-medicalgroupdetails';
    static readonly MI_PD_NUMBER_OF_MEMBERS_BY_MEDICAL_GROUP_DOWNLOAD_TYPE = 'indicesmsi-medicalgroupdetails';
    static readonly MI_PD_NUMBER_OF_MEMBERS_BY_MEDICAL_GROUP_DOWNLOAD = 'Number of Members by Medical Group Download';
    static readonly MI_PD_NUMBER_OF_MEMBERS_BY_MEDICAL_GROUP_FILENAME = 'Number of Members by Medical Group';
    static readonly MI_PD_NUMBER_OF_MEMBERS_BY_MEDICAL_GROUP_SHEETNAME = 'Number of Members by Medical Group';

    // MSI PROVIDER DEMOGRAPHIC NUMBERS OF MEMBER BY CLINIC DOWNLOAD
    static readonly MI_PD_NUMBER_OF_MEMBERS_BY_CLINIC = 'indicesmsi-clinicdetails';
    static readonly MI_PD_NUMBER_OF_MEMBERS_BY_CLINIC_DOWNLOAD_TYPE = 'indicesmsi-clinicdetails';
    static readonly MI_PD_NUMBER_OF_MEMBERS_BY_CLINIC_DOWNLOAD = 'Number of Members by Clinic Download';
    static readonly MI_PD_NUMBER_OF_MEMBERS_BY_CLINIC_FILENAME = 'Number of Members by Clinic';
    static readonly MI_PD_NUMBER_OF_MEMBERS_BY_CLINIC_SHEETNAME = 'Number of Members by Clinic';

    // MSI PROVIDER DEMOGRAPHIC NUMBERS OF MEMBER BY PROVIDER DOWNLOAD
    static readonly MI_PD_NUMBER_OF_MEMBERS_BY_PROVIDER = 'indicesmsi-numberofmemberbyproviders';
    static readonly MI_PD_NUMBER_OF_MEMBERS_BY_PROVIDER_DOWNLOAD_TYPE = 'indicesmsi-numberofmemberbyproviders';
    static readonly MI_PD_NUMBER_OF_MEMBERS_BY_PROVIDER_DOWNLOAD = 'Number of Members by Provider Download';
    static readonly MI_PD_NUMBER_OF_MEMBERS_BY_PROVIDER_FILENAME = 'Number of Members by Provider';
    static readonly MI_PD_NUMBER_OF_MEMBERS_BY_PROVIDER_SHEETNAME = 'Number of Members by Provider';

    // MSI ADDED DROPPED MEMBER COUNT DOWNLOAD
    static readonly MI_ET_ADDED_DROPPED_MEMBER_COUNT = 'indicesmsi-addedanddroppedmembercounts';
    static readonly MI_ET_ADDED_DROPPED_MEMBER_COUNT_DOWNLOAD_TYPE = 'indicesmsi-addedanddroppedmembercounts';
    static readonly MI_ET_ADDED_DROPPED_MEMBER_COUNT_DOWNLOAD = 'Added Dropped Member Counts Download';
    static readonly MI_ET_ADDED_DROPPED_MEMBER_COUNT_FILENAME = 'Added Dropped Member Counts';
    static readonly MI_ET_ADDED_DROPPED_MEMBER_COUNT_SHEETNAME = 'Added Dropped Member Counts';

    // MSI ENROLLMENT TREND DETAIL GRID DOWNLOAD
    static readonly MI_ET_ENROLLMENT_TREND_DETAIL = 'indicesmsi-enrollmenttrenddetails';
    static readonly MI_ET_ENROLLMENT_TREND_DETAIL_DOWNLOAD_TYPE = 'indicesmsi-enrollmenttrenddetails';
    static readonly MI_ET_ENROLLMENT_TREND_DETAIL_DOWNLOAD = 'Enrollment Trend Detail Download';
    static readonly MI_ET_ENROLLMENT_TREND_DETAIL_FILENAME = 'Enrollment Trend Details Grid';
    static readonly MI_ET_ENROLLMENT_TREND_DETAIL_SHEETNAME = 'Enrollment Trend Details Grid';

    // MSI ENROLLMENT COUNT DOWNLOAD
    static readonly MI_ET_ENROLLMENT_COUNT = 'indicesmsi-enrollmentcount';
    static readonly MI_ET_ENROLLMENT_COUNT_DOWNLOAD_TYPE = 'indicesmsi-enrollmentcount';
    static readonly MI_ET_ENROLLMENT_COUNT_DOWNLOAD = 'Enrollment Count Download';
    static readonly MI_ET_ENROLLMENT_COUNT_FILENAME = 'Enrollment Count';
    static readonly MI_ET_ENROLLMENT_COUNT_SHEETNAME = 'Enrollment Count';

    // MSI EXTRACT
    static readonly MI_ET_EXTRACT = 'indicesmsi-extract';
    static readonly MI_ET_EXTRACT_DOWNLOAD_TYPE = 'indicesmsi-extract';
    static readonly MI_ET_EXTRACT_DOWNLOAD = 'Membership Insights Member Details Download';
    static readonly MI_ET_EXTRACT_FILENAME = 'Membership Insights Member Details';
    static readonly MI_ET_EXTRACT_SHEETNAME = 'Member Details';


    // VBPI 4.0 MEASURE PERFORMANCE CP PATIENT GAP WORKLIST DOWNLOAD
    // static readonly VBPI_PD_BY_PATIENT = 'patientdetailbypatient-combined';
    static readonly VBPI_PD_BY_MEASURE = 'indicesvc-patientdetailbymeasure-combined';
    static readonly VBPI_PD_BY_MEASURE_DOWNLOAD_TYPE = 'indicesvc-patientdetailbymeasure-combined';
    static readonly VBPI_PD_BY_MEASURE_DOWNLOAD = 'Patient Measure Data Download';
    static readonly VBPI_PD_BY_MEASURE_FILENAME = 'Value Based Program Insights - Patient Details by Measure';
    static readonly VBPI_PD_BY_MEASURE_SHEETNAME = 'Patient Details by Measure'; //Patient Measure Data

    //VBPI Enable FUll Patient Panel by measure PD Download
    static readonly VBPI_PD_BY_MEASURE_CONFIG = 'indicesvc-patientdetailbymeasure-config';
    static readonly VBPI_PD_BY_MEASURE_CONFIG_FILENAME = 'Value Based Program Insights - Enable Full Patient Panel';
    static readonly VBPI_PD_BY_MEASURE_CONFIG_SHEETNAME = 'Patient Details by Measure ';

    // VBPI 4.0 PATIENT DETAIL BY PATIENT DOWNLOAD
    static readonly VBPI_PD_BY_PATIENT = 'indicesvc-patientdetailbypatient-combined';
    static readonly VBPI_PD_BY_BY_PATIENT_TYPE = 'indicesvc-patientdetailbymeasure-combined';
    static readonly VBPI_PD_BY_PATIENT_DOWNLOAD = 'Patient Details by Patient Data Download';
    static readonly VBPI_PD_BY_PATIENT_FILENAME = 'Value Based Program Insights - Patient Details by Patient';
    static readonly VBPI_BY_PATIENT_SHEETNAME = 'Patient Details by Patient';//Patient Details by Patient Data

    // VBPI 4.0 MEASURE PERFORMANCE CP PATIENT GAP WORKLIST DOWNLOAD

    static readonly EXCEL_PO_OS_REQUEST_NAME = 'summary';

    // static readonly EXCEL_PO_OVERALL_SUMMARY_REQUEST_NAME = 'indicesvc-drvpatientdetailbymeasure';
    static readonly VBPI_PO_OVERALL_SUMMARY_REQUEST_NAME = 'indicesvc-programoverview-download';
    static readonly VBPI_PO_OVERALL_SUMMARY_REQUEST_NAME_DOWNLOAD_TYPE = 'indicesvc-programoverview-download';
    static readonly VBPI_PO_OVERALL_SUMMARY_REQUEST_NAME_DOWNLOAD = 'Program Overall Summary Download';
    
    static readonly VBPI_PP_SELECTED_PATIENT_REQUEST_NAME = 'indicesvc-patientprofile-download';
    static readonly VBPI_PP_SELECTED_PATIENT_REQUEST_NAME_DOWNLOAD_TYPE = 'indicesvc-patientprofile-download';
    static readonly VBPI_PP_SELECTED_PATIENT_REQUEST_NAME_DOWNLOAD = 'Patient Profile Download';
    
    static readonly VBPI_PO_OVERALL_SUMMARY_REQUEST_NAME_FILENAME = 'Value Based Program Insights - Program Overview Measure Data';
    static readonly VBPI_PO_OVERALL_SUMMARY_REQUEST_NAME_SHEETNAME = 'Program Measure Data';
    static readonly VBPI_PO_OVERALL_SUMMARY_REQUEST_NAME_EXCEL_SHEETNAME = 'Program Measure Data';

    // VBPI 4.0 PROGRAM OVERVIEW PROGRAM MEASURES LIST FOR PROGRAM SUMMARY
    static readonly VBPI_PO_PROGRAM_MEASURE_STANDARD_SINGLE_REQUEST_NAME = 'indicesvc-measureswithnopatients';
    static readonly VBPI_PO_PROGRAM_MEASURE_STANDARD_RISK = 'indicesvc-measureswithnopatients-risk';
    static readonly VBPI_PO_PROGRAM_MEASURE_DOWNLOAD_TYPE = 'indicesvc-measureswithnopatients';
    static readonly VBPI_PO_PROGRAM_MEASURE_STANDARD_MULTI_OR_STAR_REQUEST_NAME = 'indicesvc-measureswithnopatients-multi';
    static readonly VBPI_PO_PROGRAM_MEASURE_STANDARD_MULTI_OR_STAR_DOWNLOAD_TYPE = 'indicesvc-measureswithnopatients-multi';
    static readonly VBPI_PO_PROGRAM_MEASURE_DOWNLOAD = 'Program Measures for Program Summary Download';
    static readonly VBPI_PO_PROGRAM_MEASURE_FILENAME = 'Value Based Program Insights - Program Measures - Full List';
    static readonly VBPI_PO_PROGRAM_MEASURE_SHEETNAME = 'Program Measures - Full List';

    // VBPI 4.0 PROVIDER PERFORMANCE MEDICAL GROUP SCORECARD
    static readonly VBPI_PP_MEDICAL_CODING_REQUEST_NAME = 'indicesvc-providerperformance-medicalgroupscorecard-download';
    static readonly VBPI_PP_MEDICAL_CODING_DOWNLOAD_TYPE = 'indicesvc-providerperformance-medicalgroupscorecard';
    static readonly VBPI_PP_MEDICAL_CODING_DOWNLOAD = 'Medical Group Scorecard Download';
    static readonly VBPI_PP_MEDICAL_CODING_FILENAME = 'Value Based Program Insights - Medical Group Scorecard';
    static readonly VBPI_PP_MEDICAL_CODING_SHEETNAME = 'Medical Group Scorecard';
    // VBPI 4.0 PROVIDER PERFORMANCE CLINIC SCORECARD
    static readonly VBPI_PP_CLINIC_REQUEST_NAME = 'indicesvc-providerperformance-clinicscorecard-download';
    static readonly VBPI_PP_CLINIC_DOWNLOAD_TYPE = 'indicesvc-providerperformance-clinicscorecard-download';
    static readonly VBPI_PP_CLINIC_DOWNLOAD = 'Clinic Scorecard Download';
    static readonly VBPI_PP_CLINIC_FILENAME = 'Value Based Program Insights - Clinic Scorecard';
    static readonly VBPI_PP_CLINIC_SHEETNAME = 'Clinic Scorecard';
    // VBPI 4.0 PROVIDER PERFORMANCE PRACTITIONER SCORECARD
    static readonly VBPI_PP_PRACTITIONER_REQUEST_NAME = 'indicesvc-providerperformance-practitionerscorecard-download';
    static readonly VBPI_PP_PRACTITIONER_DOWNLOAD_TYPE = 'indicesvc-providerperformance-practitionerscorecard-download';
    static readonly VBPI_PP_PRACTITIONER_DOWNLOAD = 'Practitioner Scorecard Download';
    static readonly VBPI_PP_PRACTITIONER_FILENAME = 'Value Based Program Insights - Practitioner Scorecard';
    static readonly VBPI_PP_PRACTITIONER_SHEETNAME = 'Practitioner Scorecard';



    static readonly FILTERS_ALL: string = 'All';
    static readonly YEARID: string = 'yearId';
    static readonly PAYER: string = 'payer';
    static readonly REPORT: string = 'reportName';
    static readonly DOWNLOAD: string = 'downloadDate';
    static readonly CATALOG_NAME_ID: string = 'catalogNameId';
    static readonly CATALOG_ID: string = 'catalogId';
    static readonly PROJECT_NAME_ID: string = 'projectNameId';
    static readonly PROJECT_ID: string = 'projectId';


    /** Client Level configurable and customizable filter constants */
    static readonly REPORTING_POPULATION_ID =  'reportingPopulationId';
    static readonly FILTERS_SHOW_ALL_POPULATION = 'Show All Population';





    /**
     * Rate monitoring constants
     */

    static readonly RM_EXTRACT_MEMBER_DETAIL = 'indicesqpi-extract';
    static readonly RM_EXTRACT_MEMBER_DETAIL_DOWNLOAD_TYPE = 'indicesqpi-extract';
    static readonly RM_EXTRACT_MEMBER_DETAIL_DOWNLOAD = 'Rate Monitoring Member Details Download';
    static readonly RM_EXTRACT_MEMBER_DETAIL_FILENAME = 'Rate Monitoring - Member Details';
    static readonly RM_EXTRACT_MEMBER_DETAIL_REPORTNAME = 'Members with rate monitoring';
    static readonly RM_EXTRACT_MEMBER_DETAIL_SHEETNAME = 'Member Details';
    //qpi rate comparisons
    static readonly RM_ANALYTIC_RUN_DOWNLOAD = 'indicesqpi-ratecomparison';
    static readonly RM_ANALYTIC_RUN_DOWNLOAD_TITLE = 'Rate Comparison Compare to Analytics Run';
    static readonly RM_ANALYTIC_RUN_FILENAME = 'Rate Comparison Compare to Other Analytics Runs';
    static readonly RM_ANALYTIC_RUN_SHEETNAME = 'Compare to Other Analytics Runs';
    static readonly RM_BENCHMARKS_DOWNLOAD = 'indicesqpi-ratecompbenchmarksdownload';
    static readonly RM_BENCHMARKS_DOWNLOAD_TITLE = 'Rate Comparison Compare to Benchmark';
    static readonly RM_BENCHMARKS_FILENAME = 'Rate Comparison Compare to Benchmark';
    static readonly RM_BENCHMARKS_SHEETNAME = 'Compare to Benchmark';

    /**
    * Rate monitoring constants
    */

    //Measure Performance Extract
    static readonly MP_EXTRACT_MEMBER_DETAIL = 'indicesqpi-extract';
    static readonly MP_EXTRACT_MEMBER_DETAIL_DOWNLOAD_TYPE = 'indicesqpi-extract';
    static readonly MP_EXTRACT_MEMBER_DETAIL_DOWNLOAD = 'Measure Performance Member Details Download';
    static readonly MP_EXTRACT_MEMBER_DETAIL_FILENAME = 'Measure Performance - Member Details';
    static readonly MP_EXTRACT_MEMBER_DETAIL_REPORTNAME = 'Members with Measure Performance';
    static readonly MP_EXTRACT_MEMBER_DETAIL_SHEETNAME = 'Member Details';

    //FIA EXTRACT TAB
    static readonly INDICES_FIA_EXTRACT: string = 'fia_interventiontype_extract_v2';
    static readonly INDICES_FIA_EXTRACT_SHEETNAME: string = 'FIA Extract';
    static readonly INDICES_DOWNLOAD_FIA_SUBTYPE: string = 'fia_interventiontype_extract_v2';
    static readonly INDICES_DOWNLOAD_FIA_HCC_SUBTYPE: string = 'fia_hcc_extract';
    static readonly INDICES_FIA_EXTRACT_FILENAME = 'FIA Extract Tab';

    static readonly INDICES_FIA_HCC_EXTRACT: string = 'fia_hcc_extract';
    static readonly INDICES_FIA_HCC_EXTRACT_SHEETNAME: string = 'FIA HCC Surveillance Extract';
    static readonly INDICES_FIA_HCC_EXTRACT_FILENAME = 'FIA HCC Surveillance Extract Tab';


    //HEI Extract

    static readonly INDICES_HEI_EXTRACT: string = 'hei_extract';
    static readonly INDICES_HEI_EXTRACT_SHEETNAME: string = 'HEI Extract';
    static readonly INDICES_HEI_EXTRACT_FILENAME = 'HEI Extract Tab';
    static readonly INDICES_DOWNLOAD_HEI_SUBTYPE: string = 'hei_extract';


    //QGI

    static readonly INDICES_QGI_EXTRACT: string = 'qgi_extract';
    static readonly INDICES_QGI_EXTRACT_SHEETNAME: string = 'QGI Extract';
    static readonly INDICES_QGI_EXTRACT_FILENAME = 'QGI Extract Tab';
    static readonly INDICES_DOWNLOAD_QGI_SUBTYPE: string = 'qgi_extract';


    static readonly INDICES_QGI_EXTRACT_BY_MEASUREKEY: string = 'qgi_extract_by_measurekey';
    static readonly INDICES_QGI_EXTRACT_BY_MEASUREKEY_SHEETNAME: string = 'QGI Extract By Measure Key';
    static readonly INDICES_QGI_EXTRACT_BY_MEASUREKEY_FILENAME = 'QGI Extract By Measure Key Tab';
    static readonly INDICES_QGI_EXTRACT_BY_MEASUREKEY_SUBTYPE: string = 'qgi_extract_by_measurekey'






    static readonly OVERVIEW_COVER_PAGE_LABELS: Object = {
        importDate: 'Data Import Date',
        medicalGroupId: 'Medical Group (ID)',
        projectId: 'Project Name',
        providerId: 'Provider Name (ID)',
        serviceId: 'Service Name',
        yearId: 'Service Year',
        specialityId: 'Provider Specialty',
        stateCode: 'State',
        siteId: 'Site Name (ID)'
    }


    static readonly ACTIVITY_LOG_COVER_PAGE_LABELS: Object = {
        importDate: 'Data Import Date',
        medicalGroupId: 'Medical Group (ID)',
        projectId: 'Project Name',
        providerId: 'Provider Name (ID)',
        serviceId: 'Service Name',
        yearId: 'Service Year',
        specialityId: 'Provider Specialty',
        stateCode: 'State',
        siteId: 'Site Name (ID)',
        lastActivity: 'Activity Status'
    }

    static readonly ACTUAL_FORECAST_LABELS: Object = {
        importDate: 'Data Import Date',
        projectId: 'Project Name',
        serviceId: 'Service Name',
        yearId: 'Service Year'
    }
    static readonly AGING_COVER_PAGE_LABELS: Object = {
        importDate: 'Data Import Date',
        medicalGroupId: 'Medical Group (ID)',
        projectId: 'Project Name',
        providerId: 'Provider Name (ID)',
        serviceId: 'Service Name',
        yearId: 'Service Year',
        specialityId: 'Provider Specialty',
        stateCode: 'State',
        siteId: 'Site Name (ID)',
        vendor: 'Vendor Name',
        pursuitGroupedStatusId: 'Pursuits Grouped Status II',
        statusId: 'Pursuits Status',
    }
    static readonly TREND_COVER_PAGE_LABELS: Object = {
        importDate: 'Data Import Date',
        medicalGroupId: 'Medical Group (ID)',
        projectId: 'Project Name',
        providerId: 'Provider Name (ID)',
        serviceId: 'Service Name',
        yearId: 'Service Year',
        specialityId: 'Provider Specialty',
        stateCode: 'State',
        siteId: 'Site Name (ID)'


    }

    static readonly EXTRACT_COVER_PAGE_LABELS: Object = {
        importDate: 'Data Import Date',
        medicalGroupId: 'Medical Group (ID)',
        projectId: 'Project Name',
        providerId: 'Provider Name (ID)',
        serviceId: 'Service Name',
        yearId: 'Service Year',
        specialityId: 'Provider Specialty',
        stateCode: 'State',
        siteId: 'Site Name (ID)',
        healthplanMemberId: "Member Name (ID)"
    }

    static readonly COMMON_COVER_PAGE_LABELS: Object = {
        payer: 'Payer',
        reportName: 'Report Name',
        downloadDate: 'Download Date'
    }


    static readonly OPPORTUNITY_RISK_COVERPAGE_LABELS: Object = {
        analyticsRunId: OIFiltersBinding.bindingInfo[OIFilterFields.ANALYTICS_RUN_NAME].label,
        measurementPeriodId: OIFiltersBinding.bindingInfo[OIFilterFields.MEASUREMENT_PERIOD].label,
        reportingPopulationId: OIFiltersBinding.bindingInfo[OIFilterFields.REPORTING_POPULATION].label,
        providerId: OIFiltersBinding.bindingInfo[OIFilterFields.PROVIDER_NAME].label,
        medicalGroupId: OIFiltersBinding.bindingInfo[OIFilterFields.MEDICAL_GROUP].label,
        lineOfBusinessId: OIFiltersBinding.bindingInfo[OIFilterFields.LINE_OF_BUSINESS].label,
        conditionCategoryDescription: OIFiltersBinding.bindingInfo[OIFilterFields.CONDITION_CATEGORY_DESCRIPTION].label,
        clinicId: OIFiltersBinding.bindingInfo[OIFilterFields.CLINIC_NAME].label,
        projectId: OIFiltersBinding.bindingInfo[OIFilterFields.PROJECT_NAME].label,
        catalogId: OIFiltersBinding.bindingInfo[OIFilterFields.CATALOG_NAME].label
    }

    static readonly CONVERGED_CARE_GAPS_COVERPAGE_LABELS: Object = {
        projectId: CCGFiltersBinding.bindingInfo[CCGFilterFields.PROJECT_NAME].label,
        reportingPopulationId: CCGFiltersBinding.bindingInfo[CCGFilterFields.REPORTING_POPULATION].label,
        lineOfBusinessId: CCGFiltersBinding.bindingInfo[CCGFilterFields.LINE_OF_BUSINESS].label,
        gapDescriptionId: CCGFiltersBinding.bindingInfo[CCGFilterFields.GAP_DESCRIPTION].label,
        medicalGroupId: CCGFiltersBinding.bindingInfo[CCGFilterFields.MEDICAL_GROUP].label,
        providerId: CCGFiltersBinding.bindingInfo[CCGFilterFields.PROVIDER_NAME].label,
        analyticRunId: CCGFiltersBinding.bindingInfo[CCGFilterFields.ANALYTICS_RUN_NAME].label,
        catalogId: CCGFiltersBinding.bindingInfo[CCGFilterFields.CATALOG_NAME].label,
        domainTypeId: CCGFiltersBinding.bindingInfo[CCGFilterFields.DOMAIN_TYPE].label,
        gapTypeId: CCGFiltersBinding.bindingInfo[CCGFilterFields.GAP_TYPE].label,
        clinicId: CCGFiltersBinding.bindingInfo[CCGFilterFields.CLINIC_NAME].label,
        rate: CCGFiltersBinding.bindingInfo[CCGFilterFields.QUALITY_MEASURE_PERFORMANCE_RATE].label
    }

    static readonly MEASURE_PERFORMANCE_COVERPAGE_LABELS: Object = {
        projectId: QPIFiltersBinding.bindingInfo[QPIFilterFields.PROJECT_NAME].label,
        measurementPeriodId: QPIFiltersBinding.bindingInfo[QPIFilterFields.MEASUREMENT_PERIOD].label,
        measureDescriptionId: QPIFiltersBinding.bindingInfo[QPIFilterFields.MEASURE_DESCRIPTION].label,
        measureAcronymId: QPIFiltersBinding.bindingInfo[QPIFilterFields.MEASURE_ACRONYM].label,
        eligibilityStatusId: QPIFiltersBinding.bindingInfo[QPIFilterFields.ELIGIBILITY].label,
        cohortId: QPIFiltersBinding.bindingInfo[QPIFilterFields.COHORT].label,
        analyticRunId: QPIFiltersBinding.bindingInfo[QPIFilterFields.FLOWCHART_RUN_NAME].label,
        catalogId: QPIFiltersBinding.bindingInfo[QPIFilterFields.CATALOG_NAME].label,
        reportingPopulationName: QPIFiltersBinding.bindingInfo[QPIFilterFields.POPULATION].label,
        measureCategoryId: QPIFiltersBinding.bindingInfo[QPIFilterFields.CATEGORY].label,
    }

    static readonly RATE_MONITORING_COVERPAGE_LABELS: Object = {
        projectId: QPIFiltersBinding.bindingInfo[QPIFilterFields.PROJECT_NAME].label,
        measurementPeriodId: QPIFiltersBinding.bindingInfo[QPIFilterFields.MEASUREMENT_PERIOD].label,
        measureDescriptionId: QPIFiltersBinding.bindingInfo[QPIFilterFields.MEASURE_DESCRIPTION].label,
        measureAcronymId: QPIFiltersBinding.bindingInfo[QPIFilterFields.MEASURE_ACRONYM].label,
        eligibilityStatusId: QPIFiltersBinding.bindingInfo[QPIFilterFields.ELIGIBILITY].label,
        cohortId: QPIFiltersBinding.bindingInfo[QPIFilterFields.COHORT].label,
        analyticRunId: QPIFiltersBinding.bindingInfo[QPIFilterFields.FLOWCHART_RUN_NAME].label,
        catalogId: QPIFiltersBinding.bindingInfo[QPIFilterFields.CATALOG_NAME].label,
        reportingPopulationName: QPIFiltersBinding.bindingInfo[QPIFilterFields.POPULATION].label,
        measureCategoryId: QPIFiltersBinding.bindingInfo[QPIFilterFields.CATEGORY].label
    }

    static readonly CCG_EXTRACT_MEMBERS_COVERPAGE_LABELS = {
        healthplanMemberId: "Member Name (ID)",
    }

    /**
   * Membership Insights constants
   */
    static readonly MEMBERSHIP_INSIGHT_COVERPAGE_LABELS: Object = {
        projectId: MIFiltersBinding.bindingInfo[MIFilterFields.PROJECT_NAME].label,
        payerStateId: MIFiltersBinding.bindingInfo[MIFilterFields.PAYER_STATE].label,
        lobId: MIFiltersBinding.bindingInfo[MIFilterFields.LOB].label,
        enrolledMonthId: MIFiltersBinding.bindingInfo[MIFilterFields.ENROLLED_MONTH].label,
        memberStateId: MIFiltersBinding.bindingInfo[MIFilterFields.MEMBER_STATE].label,
        memberCountyId: MIFiltersBinding.bindingInfo[MIFilterFields.MEMBER_COUNTY].label,
        cohortId: MIFiltersBinding.bindingInfo[MIFilterFields.COHORT].label,
        populationId: MIFiltersBinding.bindingInfo[MIFilterFields.POPULATION].label
    }

    static readonly MEMBERSHIP_INSIGHT_MD_COVERPAGE_LABELS: Object = {
        projectId: MIFiltersBinding.bindingInfo[MIFilterFields.PROJECT_NAME].label,
        payerStateId: MIFiltersBinding.bindingInfo[MIFilterFields.PAYER_STATE].label,
        lobId: MIFiltersBinding.bindingInfo[MIFilterFields.LOB].label,
        enrolledMonthId: MIFiltersBinding.bindingInfo[MIFilterFields.ENROLLED_MONTH].label,
        memberStateId: MIFiltersBinding.bindingInfo[MIFilterFields.MEMBER_STATE].label,
        memberCountyId: MIFiltersBinding.bindingInfo[MIFilterFields.MEMBER_COUNTY].label,
        cohortId: MIFiltersBinding.bindingInfo[MIFilterFields.COHORT].label,
        populationId: MIFiltersBinding.bindingInfo[MIFilterFields.POPULATION].label
    }

    static readonly MEMBERSHIP_INSIGHT_PD_COVERPAGE_LABELS: Object = {
        projectId: MIFiltersBinding.bindingInfo[MIFilterFields.PROJECT_NAME].label,
        payerStateId: MIFiltersBinding.bindingInfo[MIFilterFields.PAYER_STATE].label,
        lobId: MIFiltersBinding.bindingInfo[MIFilterFields.LOB].label,
        enrolledMonthId: MIFiltersBinding.bindingInfo[MIFilterFields.ENROLLED_MONTH].label,
        memberStateId: MIFiltersBinding.bindingInfo[MIFilterFields.MEMBER_STATE].label,
        medicalGroupId: MIFiltersBinding.bindingInfo[MIFilterFields.MEDICAL_GROUP].label,
        clinicId: MIFiltersBinding.bindingInfo[MIFilterFields.CLINIC].label,
        providerId: MIFiltersBinding.bindingInfo[MIFilterFields.PROVIDER].label,
        populationId: MIFiltersBinding.bindingInfo[MIFilterFields.POPULATION].label,
        cohortId: MIFiltersBinding.bindingInfo[MIFilterFields.COHORT].label
    }

    static readonly MEMBERSHIP_INSIGHT_ET_COVERPAGE_LABELS: Object = {
        projectId: MIFiltersBinding.bindingInfo[MIFilterFields.PROJECT_NAME].label,
        populationId: MIFiltersBinding.bindingInfo[MIFilterFields.POPULATION].label,
        payerStateId: MIFiltersBinding.bindingInfo[MIFilterFields.PAYER_STATE].label,
        lobId: MIFiltersBinding.bindingInfo[MIFilterFields.LOB].label,
        enrolledMonthId: MIFiltersBinding.bindingInfo[MIFilterFields.ENROLLED_MONTH].label,
        cohortId: MIFiltersBinding.bindingInfo[MIFilterFields.COHORT].label,

    }

    static readonly MEMBERSHIP_INSIGHT_EXTRACT_COVERPAGE_LABELS: Object = {
        projectId: MIFiltersBinding.bindingInfo[MIFilterFields.PROJECT_NAME].label,
        populationId: MIFiltersBinding.bindingInfo[MIFilterFields.POPULATION].label,
        payerStateId: MIFiltersBinding.bindingInfo[MIFilterFields.PAYER_STATE].label,
        lobId: MIFiltersBinding.bindingInfo[MIFilterFields.LOB].label,
        enrolledMonthId: MIFiltersBinding.bindingInfo[MIFilterFields.ENROLLED_MONTH].label,
        cohortId: MIFiltersBinding.bindingInfo[MIFilterFields.COHORT].label,
        memberStateId: MIFiltersBinding.bindingInfo[MIFilterFields.MEMBER_STATE].label,
        medicalGroupId: MIFiltersBinding.bindingInfo[MIFilterFields.MEDICAL_GROUP].label,
        clinicId: MIFiltersBinding.bindingInfo[MIFilterFields.CLINIC].label,
        providerId: MIFiltersBinding.bindingInfo[MIFilterFields.PROVIDER].label,
    }

    static readonly FIA_EXTRACT_COVERPAGE_LABELS: Object = {
        serviceYear: FiaFiltersBinding.bindingInfo[FiaFilterFields.SERVICE_YEAR].label,
        projectName: FiaFiltersBinding.bindingInfo[FiaFilterFields.PROJECT_NAME].label,
        payerState: FiaFiltersBinding.bindingInfo[FiaFilterFields.PAYER_STATE].label,
        lineOfBusiness: FiaFiltersBinding.bindingInfo[FiaFilterFields.LINE_OF_BUSINESS].label,
        population: FiaFiltersBinding.bindingInfo[FiaFilterFields.POPULATION].label,
        vendorName: FiaFiltersBinding.bindingInfo[FiaFilterFields.PARTNER].label,
        interventionPlanName: FiaFiltersBinding.bindingInfo[FiaFilterFields.INTERVENTION_PLAN_NAME].label,
    }


    static readonly HEI_EXTRACT_COVERPAGE_LABELS: Object = {
        projectId: "Project Name",
        analyticRunId: "Analytics Run Name",        
        measurementPeriodId: "Measurement Period",
        catalogId: "Catalog Name",
        reportingPopulationId: "Reporting Population",

        
 
        ratingYearId : "Rating Year",
        cutpointsId : "Cutpoints",
        eligibleContractId : "Eligible Contract",
        measureId : "Measure",
        subMeasureId : "SubMeasure",


        healthplanMemberId: "Member Name (ID)",




        // lineOfBusinessId: CCGFiltersBinding.bindingInfo[CCGFilterFields.LINE_OF_BUSINESS].label,
        // gapDescriptionId: CCGFiltersBinding.bindingInfo[CCGFilterFields.GAP_DESCRIPTION].label,
        // medicalGroupId: CCGFiltersBinding.bindingInfo[CCGFilterFields.MEDICAL_GROUP].label,
        // providerId: CCGFiltersBinding.bindingInfo[CCGFilterFields.PROVIDER_NAME].label,
        
        
        // domainTypeId: CCGFiltersBinding.bindingInfo[CCGFilterFields.DOMAIN_TYPE].label,
        // gapTypeId: CCGFiltersBinding.bindingInfo[CCGFilterFields.GAP_TYPE].label,
        // clinicId: CCGFiltersBinding.bindingInfo[CCGFilterFields.CLINIC_NAME].label,
        // rate: CCGFiltersBinding.bindingInfo[CCGFilterFields.QUALITY_MEASURE_PERFORMANCE_RATE].label
    }

    static readonly QGI_EXTRACT_COVERPAGE_LABELS: Object = {
        measurementPeriodId: "Measurement Period",
        analyticRunId: "Analytics Run Name",        
        catalogId: "Catalog Name",
        medicalGroupId: "Medical Group",
        clinicId: "Clinic",
        providerId: "Practitioner",
        cohortId: "Cohort",
        lineOfBusinessId: "Line of Business",
        measureAcronymId : "Measure Acronym",
        measureId : "Measure Key",
        //new user filters
        planStateId : "Plan State",
        productCodeId : "Product Code",
        
        //search filter
        healthplanMemberId: "Member Name (ID)",
    }

    static readonly QGI_EXTRACT_BY_MEASUREKEY_COVERPAGE_LABELS: Object = {
        measurementPeriodId: "Measurement Period",
        analyticRunId: "Analytics Run Name",        
        catalogId: "Catalog Name",
        medicalGroupId: "Medical Group",
        clinicId: "Clinic",
        providerId: "Practitioner",
        cohortId: "Cohort",
        lineOfBusinessId: "Line of Business",
        measureId : "Measure Key",
        //new user filters
        planStateId : "Plan State",
        productCodeId : "Product Code",
        
        //search filter
        healthplanMemberId: "Member Name (ID)",
    }
}
