
/*
* Copyright (c) 2019 – 2020, INOVALON, INC. All Rights Reserved.
* This computer program is CONFIDENTIAL and a TRADE SECRET of Inovalon, Inc.
* The receipt or possession of this program does not convey any rights to use,
* reproduce or disclose its contents in whole or in part, without the specific
* written consent of Inovalon, Inc. Any use, reproduction or disclosure of
* this program without the express written consent of Inovalon, Inc., is a
* violation of the copyright laws and may subject you to criminal prosecution.
*/

export const environment = {
  production: false,
  name: 'dev',
  title: 'Data Insights',
  SUBDOMAIN_PREFIX: 'indices3',
  BASE_URL: 'https://inovalononedev.oktapreview.com',
  CLIENT_ID: '0oa15duum67s3YI1T0h8',
  ISSUER: 'https://inovalononedev.oktapreview.com/oauth2/ausfwtb2inw6HxWUO0h7',
  REDIRECTURI: 'https://datainsights-dev.inovalon.com/callback',
  DATAINSIGHTSURL: 'https://datainsights-dev.inovalon.com/',
  PATIENT_PROFILES_ALLOWED_TABS_COUNT : 10,
  APTRINSIC_TAG: '',
  BASE_API_URL: 'https://vbpi-queryservice-dev.inovalonone.com',
  HI_TRUST_BASE_URL: 'https://dev-ioaudit-webapi.apps.rs-nonprod-int2-pcf.medassurant.local/api/v1/audit',
  INDICES_APP_URL: 'https://data-insights-dev.inovalon.com/',
  INDICES_MENU_API_URL: 'https://usermenu-service-dev.inovalon.com/',
  SPRINT_BASE_API_URL: 'https://vbpi-dataretriverservice-dev.inovalonone.com/retrieve',
  EXTRACT_COLUMNS_LAYOUT_API_URL: 'https://wtasknwkp4-vpce-02465a17c6266168d.execute-api.us-east-1.amazonaws.com/dev',
  USERINFO_API_URL: '',
  MOCK_API_URL: 'https://indices3-mock.apps.rs-nonprod-int2-pcf.medassurant.local/',
  DEV_API_URL: 'https://indices-das-dev.dal.inovalonone.com/v2/data',
  ES_DEV_API_URL: 'https://indices-cdi-dev.inovalon.com/es-dashboard/data',
  DEV_API_COVERPAGE_FIELDS_URL: 'https://indices-das-dev.dal.inovalonone.com/v2/coverpage-fields',
  DEV_API_PINNED_PROGRAMS_URL: 'https://indices-das-dev.dal.inovalonone.com/v2/pinned-programs',
  DAS_REQ_PARAM_URL: 'https://indices-das-dev.dal.inovalonone.com/v2/req-by-param',
  DEV_FILE_URL: 'https://indices-frs-dev.dal.inovalonone.com',
  DEV_API_CLIENT_CONFIG: 'https://indices-client-config-api.apps.rs-nonprod-int2-pcf.medassurant.local/api',
  SUBDOMAIN_PREFIX_INDICES: 'indices-mrr',
  REQUEST_TYPE_DATA: 'data',
  REQUEST_APP_INDICES: 'INDICESMRR',
  REQUEST_APP_INDICES_OPPORTUNITY: 'INDICESOI',
  REQUEST_APP_INDICES_CA: 'INDICESCA',
  REQUEST_APP_INDICES_QPI: 'INDICESQPI',
  REQUEST_APP_INDICES_VBPI: 'INDICESVC',
  REQUEST_APP_INDICES_MSI: 'INDICESMSI',
  REQUEST_APP_INDICES_DRM: 'INDICESDRM',
  REQUEST_APP_INDICES_ES: 'INDICESES',
  UNLIMITED_RECORD_COUNT: -1,
  DEFAULT_COUNT: 500,
  DEFAULT_DOWNLOAD_COUNT: 1000000,
  EXCEL_URL: 'https://indices-das-dev.dal.inovalonone.com/v2/extract-excel',
  OI_EXPLORE_PERMISSION: ['INDICES3.Opportunity Identified.Explorer'],
  MRR_EXPLORE_PERMISSION: ['INDICES3.Intervention Execution.Explorer'],
  CCG_EXPLORE_PERMISSION: ['INDICES3.Converged Care Gaps.Explorer'],
  ACTUAL_VS_FORECAST_PERMISSION: [
    'INDICES3.Intervention Execution.MRR.Forecast'
  ],
  okta: {
    refreshInterval: 15 * 60000, // 60000 = 1 minute
    storage: 'sessionStorage', // sessionStorage, localStorage
  },
  LOOKER_URL: 'https://indices-looker-dev.inovalon.com:9999',
  LOOKER_AUTH_URL: 'https://indices-das-dev.dal.inovalonone.com/v2/sign/auth',
  LOOKER_DEFAULT_VIEW: 'indices_mrr/mrrsiteactivity',
  BULK_USER_UPDATE_URL: 'https://usermanagement-service-dev.inovalon.com',
  USE_LOOKER: false,
  DAS_BASE_URL: "https://indices-das-dev.dal.inovalonone.com/v2",
  VBPI_TABS_FAVORITE: "https://9i74tal8xg-vpce-02465a17c6266168d.execute-api.us-east-1.amazonaws.com/dev/user-vbpi-favorites",
  REQUEST_APP_INDICES_FIA: 'FIAREALIZATION',
  DBAAS_API_URL:
  "https://dbaaservice-api.indices-dev.aws53.inovalon.global/api",
  IAP_URL:"https://apps-dev.inovalon.com",
  IDP_COOKIE:"datainsights-idp-dev",
  IDP_DOMAINHINT_COOKIE:"datainsights-domainhint-dev",
  CCG_IDENTIFIER : 22,
  MI_IDENTIFIER : 21,
  OI_IDENTIFIER : 23,
  PAI_IDENTIFIER : 25,
  FIA_IDENTIFIER: 26,
  QPI_IDENTIFIER : 27,
  HEI_IDENTIFIER : 33,
  REQUEST_APP_INDICES_HEI : "HEI",
  CRF_DATA_LIMIT: 10,
  CRF_MEMBERSEARCH_LIMIT: 10,
  QGI_IDENTIFIER : 1382,
  REQUEST_APP_INDICES_QGI : "QGI",
};
