
/*
* Copyright (c) 2019 – 2020, INOVALON, INC. All Rights Reserved.
* This computer program is CONFIDENTIAL and a TRADE SECRET of Inovalon, Inc.
* The receipt or possession of this program does not convey any rights to use,
* reproduce or disclose its contents in whole or in part, without the specific
* written consent of Inovalon, Inc. Any use, reproduction or disclosure of
* this program without the express written consent of Inovalon, Inc., is a
* violation of the copyright laws and may subject you to criminal prosecution.
*/

import { Component, OnInit } from '@angular/core';
import { OktaService } from 'src/app/factory/okta.service';
import { AppLoaderService } from '../services/app-loader/app-loader.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  constructor(private oktaService:OktaService,
    private appLoaderService:AppLoaderService,
    private cookieService: CookieService) { }

  ngOnInit() {
      this.appLoaderService.hideLoader();
      localStorage.clear();
      sessionStorage.clear();
      // this.cookieService.deleteAll();
      // this.cookieService.deleteAll("/",".inovalon.com");
      this.oktaService._logout$.next(true);
  }
}
