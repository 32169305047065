
/*
* Copyright (c) 2019 – 2020, INOVALON, INC. All Rights Reserved.
* This computer program is CONFIDENTIAL and a TRADE SECRET of Inovalon, Inc.
* The receipt or possession of this program does not convey any rights to use,
* reproduce or disclose its contents in whole or in part, without the specific
* written consent of Inovalon, Inc. Any use, reproduction or disclosure of
* this program without the express written consent of Inovalon, Inc., is a
* violation of the copyright laws and may subject you to criminal prosecution.
*/

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { version } from '../../package.json';
import { NavigationEnd, Router } from '@angular/router';
import { OktaService } from './factory/okta.service';
import { MenuService } from './services/menu.service';
import { Subscription, combineLatest, firstValueFrom,zip } from 'rxjs';
import { LoggerService } from './shared/services/logger.service';
import { IndicesUserActivityService } from './services/indices-user-activity.service';
import { AppLoaderService } from './shared/services/app-loader/app-loader.service';
import { datadogRum } from '@datadog/browser-rum';
import { AppSettingsService } from './factory/app-setting.service';
import { IAppSettings } from './factory/Iapp.settings';
import { AuthService } from '@io-angular/auth';
import { filter } from 'rxjs/operators'; 

declare global {
  interface Window { aptrinsic: any; }
}


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  applicationTitle = '';
  env = environment;
  isAuthenticated: boolean;
  isMenuCollapsed = false;
  accessTokenSubscriber: Subscription;
  upnSubscriber: any;
  customerNameSubscriber: any;
  userInfoSubscription: Subscription;
  userPermissionSubscription: Subscription;

  constructor(
    public titleService: Title,
    private oktaService: OktaService,
    public menuService: MenuService, // used in HTML
    private router: Router, // used in HTML
    private loggerService: LoggerService,
    public appLoaderService: AppLoaderService,
    private indicesUserActivityService: IndicesUserActivityService, // Logs user activity in INDICES.
    private authService : AuthService
  ) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd) // Only respond to NavigationEnd events
    ).subscribe(() => {
      const currentUrl = this.router.url; // Get the current URL path
      console.log(currentUrl);
      // Avoid setting the title when user is on the 'patient profiles memberId' page
      if (!currentUrl.includes('patient-profiles?memberId')) {
        this.titleService.setTitle(this.setApplicationTitle(this.env.production));
      }
    });
    
    this.appLoaderService.showLoader();
  }

  async ngOnInit() {
    try {

      this.authService.isAuthenticated$.subscribe((isAuthenticated)=>{
        this.isAuthenticated = isAuthenticated;
        this.oktaService.isAuthenticated = isAuthenticated;      
        // this.oktaService.upn$.next(this.authService.upn);
      })

      this.loadDatadogRumScript();
      this.loadGainsightScript();

      // Gainsight & Datadog RUM initialization
      this.userInfoSubscription = this.oktaService.userInfo$.subscribe((user) => {
        if (user) {
          this.initDatadogRumScript(user);
          this.initGainsightScript(user);
        }
      });
      const isAuthenticated = await firstValueFrom(this.authService.isAuthenticated$);
      if (isAuthenticated) {
        if (!this.menuService.isMenuLoaded.value) {
          await this.menuService.initialize();
          this.customerNameSubscriber = this.oktaService.client.subscribe(client => {
            if (client) {
              if (client.clientName.toLowerCase()) {
                this.loggerService.customerName = client.clientName.toLowerCase();
                this.upnSubscriber = this.oktaService.upn$.subscribe(upn => {
                  if (upn) {
                    this.loggerService.upn = upn;
                    this.loggerService.isDebug = upn.toLowerCase().includes('inovalon') ? true : false;
                  }
                });
              }
            }
          });
        }
        // const permissions = await firstValueFrom(this.oktaService.userPermissions$);
        // console.log("permissions : ",permissions);
        // console.log("okta redirect url : ",this.oktaService.redirectUrl);
        // if(permissions){
        //   if (!this.oktaService.redirectUrl || this.oktaService.redirectUrl === '/' || this.oktaService.redirectUrl === '/unauthorized') {
        //     this.router.navigate(['/']);
        //   } else {
        //     this.router.navigateByUrl(this.oktaService.redirectUrl);
        //   }
        // }
        // this.userPermissionSubscription = this.oktaService.userPermissions$.subscribe(permissions => {
        //   if (permissions != null) {
        //     if (!this.oktaService.redirectUrl || this.oktaService.redirectUrl === '/' || this.oktaService.redirectUrl === '/unauthorized') {
        //       this.router.navigate(['/']);
        //     } else {
        //       this.router.navigateByUrl(this.oktaService.redirectUrl);
        //     }
        //   }
        // });
      }

      this.appLoaderService.hideLoaderWithDelay();
      
    }

    catch (error) {
      this.loggerService.logger({
        type: 'error',
        class: 'AppComponent',
        method: 'ngOnInit',
        message: 'The application failed to load.',
        error
      });
      this.appLoaderService.hideLoader();
    }
  }

  ngOnDestroy(): void {
    this.accessTokenSubscriber.unsubscribe();
    // this.userPermissionSubscription.unsubscribe();
  }

  setApplicationTitle(productionEnvironment: boolean): string {
    if (productionEnvironment) {
      // this exists to unit test the application title
      this.applicationTitle = this.env.title;
    } else {
      this.applicationTitle = this.env.title; //+ '-' + this.env.name + '-v' + version;
    }
    return this.applicationTitle;
  }

  collapseMenu(value) {
    this.isMenuCollapsed = value;
  }

  private loadGainsightScript() {
    if (this.env.APTRINSIC_TAG) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.text = `(function (n, t, a, e) {
                      var i = "aptrinsic"; n[i] = n[i] || function () {
                      (n[i].q = n[i].q || []).push(arguments)
                      }, n[i].p = e;
                      var r = t.createElement("script"); r.async = !0, r.src = a + "?a=" + e;
                      var c = t.getElementsByTagName("script")[0]; c.parentNode.insertBefore(r, c)
                      })(window, document, "https://web-sdk.aptrinsic.com/api/aptrinsic.js", "${this.env.APTRINSIC_TAG}");`;
      document.getElementsByTagName('head')[0].appendChild(script);
    }
    if (this.env.production) {
      const libScript = document.createElement('script');
      libScript.id = 'ze-snippet';
      libScript.src = 'https://static.zdassets.com/ekr/snippet.js?key=707e9166-707f-4455-adcb-04a922772a01'
      document.getElementsByTagName('head')[0].appendChild(libScript);
    }

  }

  private initGainsightScript(user) {
    if (this.env.APTRINSIC_TAG && window.aptrinsic) {
      this.oktaService.client.subscribe((client) => {
        if (client) {
          this.menuService.getPxData(client, user).subscribe((pxData) => {
            window.aptrinsic('identify', pxData.userFilterData, pxData.userAccountInfo);
          });
        }
      });
    }
    // if (this.env.production) {
    //   const script = document.createElement('script');
    //   script.type = 'text/javascript';
    //   script.text = `
    //   zE(function () { zE.hide(); });
    //   zE('webWidget', 'updateSettings', {
    //     webWidget: {
    //       zIndex: 99999999999,
    //       position: { horizontal: 'right', vertical: 'bottom' },
    //       chat: {
    //         suppress: true
    //       },
    //       contactForm: {
    //         title: { '*': 'Submit a ticket' },
    //         ticketForms: [{ id: 5320539794196 }]
    //       }
    //     }
    //   });`;
    //   document.getElementsByTagName('head')[0].appendChild(script);
    // }
  }

  private loadDatadogRumScript() {
    datadogRum.init({
      applicationId: '6834634c-6e35-4117-a66d-131920d7a8b5',
      clientToken: 'pub2e22c8111b9e04a9e7681aa5639da2c4',
      site: 'datadoghq.com',
      service: 'indices-3.0',
      env: 'INDICES-3.0-' + localStorage.getItem('stage'),
      //  version: '1.0.0',
      sampleRate: 100,
      trackInteractions: true
    });
  }

  private initDatadogRumScript(user) {
    datadogRum.setUser({
      id: user.upn
    });
  }

}
