import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { DownloadItemStatus, DownloadItem } from 'src/app/models/download-item.model';
import { LookerConstants } from 'src/app/shared/constants/looker-constants';
import { DownloadFileService } from 'src/app/services/download-file.service';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { OktaService } from 'src/app/factory/okta.service';

@Component({
  selector: 'app-download-list-item',
  templateUrl: './download-list-item.component.html',
  styleUrls: ['./download-list-item.component.css']
})
export class DownloadListItemComponent implements OnInit {
  @Input() item: DownloadItem
  downloadFileStatus: typeof DownloadItemStatus = DownloadItemStatus
  lookerConstants: typeof LookerConstants = LookerConstants
  dateFormat: string = "MM.dd.yy HH.mm.ss"
  expiryDateToDisplay: string = '-'
  isDownloading: boolean = false
  fileName: string = ''
  seeMore: boolean = false
  commentTextToDisplay: string = ''
  showTruncatedLinks: boolean = false
  private readonly TRUNCATE_TEXT_LENGTH: number = 80
  constructor(
    private oktaService: OktaService,
    public downloadFileService: DownloadFileService,
    public datePipe: DatePipe,
    private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    if (this.item.status === DownloadItemStatus.COMPLETED || this.item.status === DownloadItemStatus.DOWNLOADED) {
      const hoursDiff: number = DownloadFileService.FILE_EXPIRY_HOURS - moment().diff(this.item.completionDate, 'hours');
      this.expiryDateToDisplay = `Available for ${hoursDiff} hour${(hoursDiff === 1) ? '' : 's'}`;
    }
    if (this.item.comment && this.item.comment.length > this.TRUNCATE_TEXT_LENGTH) {
      this.showTruncatedLinks = true;
      this.seeMore = true;
    }
    this.commentTextToDisplay = this.getTruncatedCommentText(this.item.comment);
  }

  downloadFile() {
    if (this.isDownloading) { return; }
    this.isDownloading = true;
    this.downloadFileService.downloadFile(this.item, this.getFileName()).subscribe(() => {
      this.isDownloading = false
    }, err => {
      this.isDownloading = false;
    });
  }

  textLink($event: PointerEvent) {
    $event.preventDefault();
    if (this.seeMore) {
      this.commentTextToDisplay = this.item.comment;
    } else {
      this.commentTextToDisplay = this.getTruncatedCommentText(this.item.comment);
    }
    this.seeMore = !this.seeMore;
  }

  getTruncatedCommentText(text: string): string {
    return text.substring(0, 80);
  }

  convertUTCDateToLocalDate(date) {
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();
    newDate.setHours(hours - offset);
    return newDate;
  }

  getFileName() {
    let fileName = '';
    let utcDate = this.item.requestDate.toISOString();
    let d = this.convertUTCDateToLocalDate(new Date(utcDate));
    const requestDate = d.toLocaleString();
    switch (this.item.requestName) {
      case LookerConstants.INDICES_MRR_SITE_CHARTS: {
        fileName = `${LookerConstants.OVERVIEW_SITE_DETAILS_CHARTS_FILENAME} ${this.datePipe.transform(requestDate, this.dateFormat)}`;
        break;
      }
      case LookerConstants.INDICES_MRR_SITE_PURSUITS: {
        fileName = `${LookerConstants.OVERVIEW_SITE_DETAILS_PURSUITS_FILENAME} ${this.datePipe.transform(requestDate, this.dateFormat)}`;
        break;
      }
      case LookerConstants.INDICES_MRR_SITE_MRR_STATUS_CHARTS: {
        fileName = `${LookerConstants.OVERVIEW_MRR_STATUS_CHARTS_FILENAME} ${this.datePipe.transform(requestDate, this.dateFormat)}`;
        break;
      }
      case LookerConstants.INDICES_MRR_SITE_MRR_STATUS_PURSUITS: {
        fileName = `${LookerConstants.OVERVIEW_MRR_STATUS_PURSUITS_FILENAME} ${this.datePipe.transform(requestDate, this.dateFormat)}`;
        break;
      }
      case LookerConstants.INDICES_MRR_AF_CHARTS:
      case LookerConstants.INDICES_MRR_AF_PURSUITS: {
        fileName = `${LookerConstants.AF_FILENAME} ${this.datePipe.transform(requestDate, this.dateFormat)}`;
        break;
      }
      case LookerConstants.INDICES_MRR_AGING_CHARTS: {
        fileName = `${LookerConstants.AGING_CHARTS_FILENAME} ${this.datePipe.transform(requestDate, this.dateFormat)}`;
        break;
      }
      case LookerConstants.INDICES_MRR_AGING_PURSUITS: {
        fileName = `${LookerConstants.AGING_PURSUITS_FILENAME} ${this.datePipe.transform(requestDate, this.dateFormat)}`;
        break;
      }
      case LookerConstants.INDICES_MRR_ACTIVITY_LOG_CHARTS:
      case LookerConstants.INDICES_MRR_ACTIVITY_LOG_PURSUITS: {
        fileName = `${LookerConstants.ACTIVITY_LOG_SITE_DETAILS_FILENAME} ${this.datePipe.transform(requestDate, this.dateFormat)}`;
        break;
      }
      case LookerConstants.INDICES_MRR_EXTRACT: {
        fileName = `${LookerConstants.EXTRACT_OVERVIEW_FILENAME} ${this.datePipe.transform(requestDate, this.dateFormat)}`;
        break;
      }
      case LookerConstants.INDICES_MRR_EXTRACT_ACTIVITY_DRILL_DOWN: {
        fileName = `${LookerConstants.EXTRACT_ACTIVITY_DRILL_DOWN_FILENAME} ${this.datePipe.transform(requestDate, this.dateFormat)}`;
        break;
      }
      case LookerConstants.INDICES_MRR_TREND_WEEKLY_ABS_CHARTS:
      case LookerConstants.INDICES_MRR_TREND_WEEKLY_CHNG_CHARTS:
      case LookerConstants.INDICES_MRR_TREND_MONTHLY_ABS_CHARTS:
      case LookerConstants.INDICES_MRR_TREND_MONTHLY_CHNG_CHARTS:
      case LookerConstants.INDICES_MRR_TREND_WEEKLY_ABS_PURSUITS:
      case LookerConstants.INDICES_MRR_TREND_WEEKLY_CHNG_PURSUITS:
      case LookerConstants.INDICES_MRR_TREND_MONTHLY_ABS_PURSUITS:
      case LookerConstants.INDICES_MRR_TREND_MONTHLY_CHNG_PURSUITS: {
        fileName = `${LookerConstants.TREND_FILENAME} ${this.datePipe.transform(requestDate, this.dateFormat)}`;
        break;
      }
      // <--OPPORTUNITY IDENTIFIED
      case LookerConstants.OPPORTUNITY_BY_GAP_CONFIDENCE_LEVEL: {
        fileName = `${LookerConstants.OI_OPPORTUNITY_BY_GAP_CONFIDENCE_FILENAME} ${this.datePipe.transform(requestDate, this.dateFormat)}`;
        break;
      }
      case LookerConstants.MEMBER_LEVEL_POTENTIAL_FACTOR: {
        fileName = `${LookerConstants.OI_MEMBER_LEVEL_POTENTIAL_FACTOR_FILENAME} ${this.datePipe.transform(requestDate, this.dateFormat)}`;
        break;
      }
      case LookerConstants.EXTRACT_MEMBER_DETAIL: {
        fileName = `${LookerConstants.OI_EXTRACT_MEMBER_DETAIL_FILENAME} ${this.datePipe.transform(requestDate, this.dateFormat)}`;
        break;
      }
      case LookerConstants.OPPORTUNITY_BY_CONDITION_CATEGORY: {
        fileName = `${LookerConstants.OI_OPPORTUNITY_BY_CONDITION_CATEGORY_FILENAME} ${this.datePipe.transform(requestDate, this.dateFormat)}`;
        break;
      }
      // OPPORTUNITY IDENTIFIED-->

      // <--Encounter Support
      case LookerConstants.ES_EXTRACT_PV_MEMBER_DETAIL: {
        fileName = `Inov_${this.oktaService.client.value.clientName}_${LookerConstants.ES_EXTRACT_PV_SHEETNAME}_${this.datePipe.transform(requestDate, this.dateFormat)}`;
        break;
      }
      case LookerConstants.ES_EXTRACT_TCV_MEMBER_DETAIL: {
        fileName = `Inov_${this.oktaService.client.value.clientName}_${LookerConstants.ES_EXTRACT_TCV_SHEETNAME}_${this.datePipe.transform(requestDate, this.dateFormat)}`;
        break;
      }
      case LookerConstants.ES_EXTRACT_VV_MEMBER_DETAIL: {
        fileName = `Inov_${this.oktaService.client.value.clientName}_${LookerConstants.ES_EXTRACT_VV_SHEETNAME}_${this.datePipe.transform(requestDate, this.dateFormat)}`;
        break;
      }
      // Encounter Support-->

      // <--CONVERGED CARE GAPS
      case LookerConstants.CCG_EXTRACT_MEMBER_DETAIL: {
        fileName = `${LookerConstants.CCG_EXTRACT_MEMBER_DETAIL_FILENAME} ${this.datePipe.transform(requestDate, this.dateFormat)}`;
        break;
      }

      case LookerConstants.CCG_TOP_GAPS_BY_GAP_DESCRIPTION: {
        fileName = `${LookerConstants.CCG_TOP_GAPS_BY_GAP_DESCRIPTION_FILENAME} ${this.datePipe.transform(requestDate, this.dateFormat)}`;
        break;
      }


      case LookerConstants.CCG_RISK_GAPS_DOWNLOAD_TYPE: {
        fileName = `${LookerConstants.CCG_RISK_GAPS_FILENAME} ${this.datePipe.transform(requestDate, this.dateFormat)}`;
        break;
      }

      case LookerConstants.TOP_MCP_GRID_PROVIDER: {
        fileName = `${LookerConstants.TOP_MCP_GRID_PROVIDER_FILENAME} ${this.datePipe.transform(requestDate, this.dateFormat)}`;
        break;
      }
      case LookerConstants.TOP_MCP_GRID_CLINIC: {
        fileName = `${LookerConstants.TOP_MCP_GRID_CLINIC_FILENAME} ${this.datePipe.transform(requestDate, this.dateFormat)}`;
        break;
      }
      case LookerConstants.TOP_MCP_GRID_MEDICAL_GROUP: {
        fileName = `${LookerConstants.TOP_MCP_GRID_MEDICAL_GROUP_FILENAME} ${this.datePipe.transform(requestDate, this.dateFormat)}`;
        break;
      }

      case LookerConstants.CCG_QUALITY_GAPS: {
        fileName = `${LookerConstants.CCG_QUALITY_GAPS_FILENAME} ${this.datePipe.transform(requestDate, this.dateFormat)}`;
        break;
      }
      // CONVERGED CARE GAPS-->
      //MEASURE PERFORMACE SUMMARY
      case LookerConstants.QPI_MP_CURRENT_MEASURE_PERFORMANCE: {
        fileName = `${LookerConstants.QPI_MP_CURRENT_MEASURE_PERFORMANCE_FILENAME} ${this.datePipe.transform(requestDate, this.dateFormat)}`;
        break;
      }
      //MEASURE PERFORMACE COMPARE CURRENT RATE AND COMPARE PERFORMANCE TO
      case LookerConstants.QPI_MP_COMPARE_CURRENT_RATE: {
        fileName = `${LookerConstants.QPI_MP_COMPARE_CURRENT_RATE_DOWNLOAD_FILENAME} ${this.datePipe.transform(requestDate, this.dateFormat)}`;
        break;
      }
      case LookerConstants.QPI_MP_COMPARE_PERFORMANCE_RATE: {
        fileName = `${LookerConstants.QPI_MP_COMPARE_CURRENT_RATE_BENCHMARK_DOWNLOAD_FILENAME} ${this.datePipe.transform(requestDate, this.dateFormat)}`;
        break;
      }
      // MEASURE DEMOGRAPHIC SUMMARY
      case LookerConstants.QPI_MD_CURRENT_MEASURE_PERFORMANCE: {
        fileName = `${LookerConstants.QPI_MD_CURRENT_MEASURE_PERFORMANCE_FILENAME} ${this.datePipe.transform(requestDate, this.dateFormat)}`;
        break;
      }


      // <--QPI MEASURE DEMOGRAPHIC GEOGRAPHIC DISTRIBUTION
      case LookerConstants.QPI_MD_GEOGRAPHIC_DISTRIBUTION: {
        fileName = `${LookerConstants.QPI_MD_GEOGRAPHIC_DISTRIBUTION_FILENAME} ${this.datePipe.transform(requestDate, this.dateFormat)}`;
        break;
      }
      // QPI MEASURE DEMOGRAPHIC GEOGRAPHIC DISTRIBUTION-->

      // <--QPI MEASURE DEMOGRAPHIC ETHNICITY DISTRIBUTION
      case LookerConstants.QPI_MD_ETHNICITY_DISTRIBUTION: {
        fileName = `${LookerConstants.QPI_MD_ETHNICITY_DISTRIBUTION_FILENAME} ${this.datePipe.transform(requestDate, this.dateFormat)}`;
        break;
      }
      // QPI MEASURE DEMOGRAPHIC ETHNICITY DISTRIBUTION-->
      // <--QPI MEASURE DEMOGRAPHIC RACE DISTRIBUTION
      case LookerConstants.QPI_MD_RACE_DISTRIBUTION: {
        fileName = `${LookerConstants.QPI_MDRACE_DISTRIBUTION_FILENAME} ${this.datePipe.transform(requestDate, this.dateFormat)}`;
        break;
      }
      // QPI MEASURE DEMOGRAPHIC AGE GENDER DISTRIBUTION-->
      // <--QPI MEASURE DEMOGRAPHIC AGE GENDER DISTRIBUTION
      case LookerConstants.QPI_MD_AGE_GENDER_DISTRIBUTION: {
        fileName = `${LookerConstants.QPI_MD_AGE_GENDER_DISTRIBUTION_FILENAME} ${this.datePipe.transform(requestDate, this.dateFormat)}`;
        break;
      }

      //QPI EXTRACT DOWNLOAD
      //Need to review this code block
      case LookerConstants.RM_EXTRACT_MEMBER_DETAIL: {
        if (localStorage.getItem(this.item.id) == 'true') {
          fileName = `${LookerConstants.RM_EXTRACT_MEMBER_DETAIL_FILENAME} ${this.datePipe.transform(requestDate, this.dateFormat)}`;
        }
        else {
          fileName = `${LookerConstants.MP_EXTRACT_MEMBER_DETAIL_FILENAME} ${this.datePipe.transform(requestDate, this.dateFormat)}`;
        }

        break;
      }

      case LookerConstants.QPI_RM_RATE_OVERVIEW_DOWNLOAD:
        fileName = `${LookerConstants.QPI_RM_RATE_OVERVIEW_FILENAME} ${this.datePipe.transform(requestDate, this.dateFormat)}`;
        break;

      // case LookerConstants.MP_EXTRACT_MEMBER_DETAIL:
      //   fileName = `${LookerConstants.MP_EXTRACT_MEMBER_DETAIL_FILENAME} ${this.datePipe.transform(requestDate, this.dateFormat)}`;
      //   break;

      case LookerConstants.RM_ANALYTIC_RUN_DOWNLOAD:
        fileName = `${LookerConstants.RM_ANALYTIC_RUN_DOWNLOAD_TITLE} ${this.datePipe.transform(requestDate, this.dateFormat)}`;
        break;

      case LookerConstants.RM_BENCHMARKS_DOWNLOAD: {
        fileName = `${LookerConstants.RM_BENCHMARKS_FILENAME} ${this.datePipe.transform(requestDate, this.dateFormat)}`;
        break;
      }
      // QPI ADMINTRATIVE RATE TREND DOWNLOAD
      case LookerConstants.QPI_MP_ADMINISTRATIVE_RATE_TREND: {
        fileName = `${LookerConstants.QPI_MP_ADMINISTRATIVE_RATE_TREND_FILENAME} ${this.datePipe.transform(requestDate, this.dateFormat)}`;
        break;
      }
      // QPI ADMINTRATIVE RATE TREND DOWNLOAD
      case LookerConstants.QPI_MP_ADMINISTRATIVE_RATE_TREND_CHILD: {
        fileName = `${LookerConstants.QPI_MP_ADMINISTRATIVE_RATE_TREND_CHILD_FILENAME} ${this.datePipe.transform(requestDate, this.dateFormat)}`;
        break;
      }
      // QPI MEASURE DEMOGRAPHIC AGE GENDER DISTRIBUTION-->


      //VBPI 4.0 MEASURE PERFORMANCE CP PATIENT GAP WORKLIST DOWNLOAD
      case LookerConstants.VBPI_PD_BY_MEASURE: {
        fileName = `${LookerConstants.VBPI_PD_BY_MEASURE_SHEETNAME} ${this.datePipe.transform(requestDate, this.dateFormat)}`;
        break;
      }
      //VBPI 4.0 MEASURE PERFORMANCE CP PATIENT GAP WORKLIST DOWNLOAD
      case LookerConstants.VBPI_PD_BY_MEASURE_CONFIG: {
        fileName = `${LookerConstants.VBPI_PD_BY_MEASURE_CONFIG_SHEETNAME} ${this.datePipe.transform(requestDate, this.dateFormat)}`;
        break;
      }
      //VBPI 4.0 PROVIDER PERFORMANCE MEDICAL CODING DOWNLOAD
      case LookerConstants.VBPI_PP_MEDICAL_CODING_REQUEST_NAME: {
        fileName = `${LookerConstants.VBPI_PP_MEDICAL_CODING_SHEETNAME} ${this.datePipe.transform(requestDate, this.dateFormat)}`;
        break;
      }
      //VBPI 4.0 PROVIDER PERFORMANCE CLINIC DOWNLOAD
      case LookerConstants.VBPI_PP_CLINIC_REQUEST_NAME: {
        fileName = `${LookerConstants.VBPI_PP_CLINIC_SHEETNAME} ${this.datePipe.transform(requestDate, this.dateFormat)}`;
        break;
      }
      //VBPI 4.0 PROVIDER PERFORMANCE PRACTITIONER DOWNLOAD
      case LookerConstants.VBPI_PP_PRACTITIONER_REQUEST_NAME: {
        fileName = `${LookerConstants.VBPI_PP_PRACTITIONER_SHEETNAME} ${this.datePipe.transform(requestDate, this.dateFormat)}`;
        break;
      }

      //MSI AGE GENDER DISTRIBUTION LINE OF BUSNISS
      case LookerConstants.MI_MD_AGE_GENDER_DISTRIBUTION: {
        fileName = `${LookerConstants.MI_MD_AGE_GENDER_DISTRIBUTION_FILENAME} ${this.datePipe.transform(requestDate, this.dateFormat)}`;
        break;
      }

      //MSI PROVIDER DEMOGRAPHIC NOS OF MEMBERS BY MEDICAL GROUP
      case LookerConstants.MI_PD_NUMBER_OF_MEMBERS_BY_MEDICAL_GROUP: {
        fileName = `${LookerConstants.MI_PD_NUMBER_OF_MEMBERS_BY_MEDICAL_GROUP_FILENAME} ${this.datePipe.transform(requestDate, this.dateFormat)}`;
        break;
      }

      //MSI PROVIDER DEMOGRAPHIC NOS OF MEMBERS BY CLINIC
      case LookerConstants.MI_PD_NUMBER_OF_MEMBERS_BY_CLINIC: {
        fileName = `${LookerConstants.MI_PD_NUMBER_OF_MEMBERS_BY_CLINIC_FILENAME} ${this.datePipe.transform(requestDate, this.dateFormat)}`;
        break;
      }

      //MSI PROVIDER DEMOGRAPHIC NOS OF MEMBERS BY PROVIDER
      case LookerConstants.MI_PD_NUMBER_OF_MEMBERS_BY_PROVIDER: {
        fileName = `${LookerConstants.MI_PD_NUMBER_OF_MEMBERS_BY_PROVIDER_FILENAME} ${this.datePipe.transform(requestDate, this.dateFormat)}`;
        break;
      }

      //MSI MEMEBER DEMOGRAPHICS ENROLLMENT BY GEOGRAPHY
      case LookerConstants.MI_MD_ENROLLMENT_BY_GEOGRAPHY: {
        fileName = `${LookerConstants.MI_MD_ENROLLMENT_BY_GEOGRAPHY_FILENAME} ${this.datePipe.transform(requestDate, this.dateFormat)}`;
        break;
      }

      //MSI ET ADDED AND DROPPED MEMBER COUNTS
      case LookerConstants.MI_ET_ADDED_DROPPED_MEMBER_COUNT: {
        fileName = `${LookerConstants.MI_ET_ADDED_DROPPED_MEMBER_COUNT_FILENAME} ${this.datePipe.transform(requestDate, this.dateFormat)}`;
        break;
      }

      //MSI ET ADDED AND DROPPED MEMBER COUNTS
      case LookerConstants.MI_ET_ENROLLMENT_COUNT: {
        fileName = `${LookerConstants.MI_ET_ENROLLMENT_COUNT_FILENAME} ${this.datePipe.transform(requestDate, this.dateFormat)}`;
        break;
      }

      //MSI ET ENROLLMENT TREND DETAIL
      case LookerConstants.MI_ET_ENROLLMENT_TREND_DETAIL: {
        fileName = `${LookerConstants.MI_ET_ENROLLMENT_TREND_DETAIL_FILENAME} ${this.datePipe.transform(requestDate, this.dateFormat)}`;
        break;
      }

      //MSI ET EXTRACT
      case LookerConstants.MI_ET_EXTRACT: {
        fileName = `${LookerConstants.MI_ET_EXTRACT_FILENAME} ${this.datePipe.transform(requestDate, this.dateFormat)}`;
        break;
      }
      //VBPI 4.0 program overview
      case LookerConstants.VBPI_PO_PROGRAM_MEASURE_STANDARD_MULTI_OR_STAR_DOWNLOAD_TYPE:
      case LookerConstants.VBPI_PO_PROGRAM_MEASURE_STANDARD_RISK:
      case LookerConstants.VBPI_PO_PROGRAM_MEASURE_STANDARD_SINGLE_REQUEST_NAME: {
        fileName = `${LookerConstants.VBPI_PO_PROGRAM_MEASURE_SHEETNAME} ${this.datePipe.transform(requestDate, this.dateFormat)}`;
        break;
      }

      case LookerConstants.VBPI_PO_OVERALL_SUMMARY_REQUEST_NAME: {
        fileName = `${LookerConstants.VBPI_PO_OVERALL_SUMMARY_REQUEST_NAME_SHEETNAME} ${this.datePipe.transform(requestDate, this.dateFormat)}`;
        break;
      }
      //VBPI 4.0 PATIENT DETAIL BY PATIENT
      case LookerConstants.VBPI_PD_BY_PATIENT: {
        fileName = `${LookerConstants.VBPI_BY_PATIENT_SHEETNAME} ${this.datePipe.transform(requestDate, this.dateFormat)}`;
        break;
      }
      //FIA EXTRACT TAB 
      case LookerConstants.INDICES_FIA_EXTRACT: {
        fileName = `${LookerConstants.INDICES_FIA_EXTRACT_SHEETNAME} ${this.datePipe.transform(requestDate, this.dateFormat)}`;
        break;
      }

      case LookerConstants.INDICES_FIA_EXTRACT: {
        fileName = `${LookerConstants.INDICES_FIA_EXTRACT_SHEETNAME} ${this.datePipe.transform(requestDate, this.dateFormat)}`;
        break;
      }


      case LookerConstants.INDICES_FIA_HCC_EXTRACT: {
        fileName = `${LookerConstants.INDICES_FIA_HCC_EXTRACT_SHEETNAME} ${this.datePipe.transform(requestDate, this.dateFormat)}`;
        break;
      }

      case LookerConstants.INDICES_HEI_EXTRACT: {
        fileName = `${LookerConstants.INDICES_HEI_EXTRACT_SHEETNAME} ${this.datePipe.transform(requestDate, this.dateFormat)}`;
        break;
      }

      case LookerConstants.INDICES_QGI_EXTRACT: {
        fileName = `${LookerConstants.INDICES_QGI_EXTRACT_SHEETNAME} ${this.datePipe.transform(requestDate, this.dateFormat)}`;
        break;
      }

      case LookerConstants.INDICES_QGI_EXTRACT_BY_MEASUREKEY: {
        fileName = `${LookerConstants.INDICES_QGI_EXTRACT_BY_MEASUREKEY_SHEETNAME} ${this.datePipe.transform(requestDate, this.dateFormat)}`;
        break;
      }

      default:
        fileName = '';
    }
    return fileName;
  }
}
